"use client";
import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import ProtectedRoute from "./components/security/ProtectedRoute";
import DashboardOverview from "./pages/dashboard/HomePage";
import SignupPage from "./pages/auth/SignupPage";
import ResumeFormattingPage from "./pages/dashboard/ResumeFormattingPage";
import PaymentPage from "./pages/dashboard/PaymentPage";
import MyProfilePage from "./pages/dashboard/MyProfilePage";
import SettingsPage from "./pages/dashboard/SettingsPage";
import { useDispatch } from "react-redux";
import CreateResume from "./createresume";
import Templates from "./templates";
import Settings from "./components/ui/Dashboard/Settings";
import { fetchUser } from "./state/actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import PageNotFound from "./pages/404 Page";
import PaySuccess from "./pages/paymentpages/pay-success";
import PaymentFailed from "./pages/paymentpages/pay-failed";
import ProfilePage from "./pages/profilepage";
import VerifyEmailPage from "./pages/dashboard/VerifyEmailPage";
import ForgotPassWord from "./pages/forgot pass page/forgotpass";
import ResetPassword from "./pages/forgot pass page/resetpass";
import ChooseTemplate from "./pages/resume templates";
import ContactSupport from "./pages/contact Support/support";
import Home from "./pages/home/home";
import { AnimatePresence } from "framer-motion";
import Timer from "./timer";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  const location = useLocation();
  return (
    <AnimatePresence>
      {/* <Timer /> */}
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Home />} />

        {/* Auth pages */}
        <Route path="/auth">
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
        </Route>

        {/* Dashboard routes */}
        <Route path="/dashboard">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <DashboardOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="resume-formatting"
            element={
              <ProtectedRoute>
                <ResumeFormattingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="payment"
            element={
              <ProtectedRoute>
                <PaymentPage />
              </ProtectedRoute>
            }></Route>
          <Route
            path="my-profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />

          <Route
            path="create-resume/:template"
            element={
              <ProtectedRoute>
                <Provider store={store}>
                  <CreateResume />
                </Provider>
              </ProtectedRoute>
            }
          />

          {/* CREATE RESUME - ADVANCED DROPDOWN */}
        </Route>
        {/* Choose Templates */}
        <Route path="/create-resume/:template">
          <Route
            path="templates"
            element={
              <ProtectedRoute>
                <Templates />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route key="pagenotfound" element={<PageNotFound />} path="/*" />
        <Route
          key="pay-successful"
          element={<PaySuccess />}
          path="/dashboard/payment/payment-success"
        />
        <Route
          key="pay-failed"
          element={<PaymentFailed />}
          path="/dashboard/payment/payment-failed"
        />
        <Route
          key="verify-email"
          element={<VerifyEmailPage />}
          path="/verify/:email/:token"
        />
        <Route
          key="forgot-pass"
          element={<ForgotPassWord />}
          path="/auth/forgot-password"
        />
        <Route
          key="reset-pass"
          element={<ResetPassword />}
          path="/auth/reset-password/:token"
        />
        <Route
          key="reset-pass"
          element={<ContactSupport />}
          path="/contact-support"
        />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
