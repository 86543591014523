import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (userId, thunkAPI) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      Navigate("/auth/login");
    }
    console.log(token);
    await axios
      .get("/api/v1/token-validation", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        // alert("hello");
        console.log(error);
        Navigate("/auth/login");
      });
  }
);

export default fetchUser;
