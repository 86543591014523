import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "../../../components/ui/Dashboard";
import DashboardHome from "../../../components/ui/Dashboard/DashboardHome";
import { fetchUser } from "../../../state/actions/authActions";

function DashboardOverview() {
  const dispatch = useDispatch();
  //   Protect Dashboard page
  const Router = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);

  return (
    <>
      <ToastContainer />
      {isUserLoggedIn && (
        <Dashboard page="my-resumes">
          <DashboardHome />
        </Dashboard>
      )}
    </>
  );
}

export default DashboardOverview;
