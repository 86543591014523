import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  PDFDownloadLink,
  Font,
  Image,
} from "@react-pdf/renderer";
import { Provider } from "react-redux";
import store from "../store";
import exportAsImage from "./resumecontrolbar";
import { setResumeImage4 } from "../state/reducers/resumeimageSlice";
import { useNavigate } from "react-router-dom";
const Resume4 = () => {
  const dispatch = useDispatch();
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage4(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [3000]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);

  useEffect(() => {
    let container = containerRef.current;

    if (!colorValue && !container) return;

    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const imageValue = useSelector((state) => state.image.imageValue);

  const navigate = useNavigate();
  const Resume4Download = () => {
    Font.register({
      family: "Roboto-regular",
      src: require("../fonts/Roboto/Roboto-Regular.ttf"),
    });
    Font.register({
      family: "Roboto-semi",
      src: require("../fonts/Roboto/Roboto-Medium.ttf"),
    });

    Font.register({
      family: "Roboto-bold",
      src: require("../fonts/Roboto/Roboto-Bold.ttf"),
    });
    const styles = {
      container: {
        width: "100%",
        padding: "0px",
      },
      heroSectionImage: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "5px",
      },
      heroSectionWithoutImage: {
        width: "100%",
        backgroundColor: "#ffe14c",
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        fontSize: "9px",
        padding: "30px",
        color: "#0d111a",
        justifyContent: "center",
      },
      imgSection: {
        width: "30%",
        height: "150px",
      },
      img: {
        width: "100%",
        height: "150px",
      },
      textSectionImage: {
        width: "70%",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffe14c",
        gap: "7px",
        color: "#0d111a",
        fontSize: "9px",
        justifyContent: "center",
      },
      name: {
        fontSize: "18px",
        fontFamily: "Roboto-bold",
      },
      jobtitle: {
        fontFamily: "Roboto-semi",
        marginTop: "5px",
      },
      namePortionWithoutImg: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "30px",
      },
      nameDiv: {
        width: "50%",
      },
      nameWithoutImg: {
        fontSize: "25px",
        fontFamily: "Roboto-bold",
      },
      textSectionWithoutImage: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        fontSize: "11px",
      },
      secondSectionMainContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: "25px",
        fontSize: "10px",
        justifyContent: "center",
        backgroundColor: "white",
      },
      sectionSectionChildContainer: {
        width: "85%",
        display: "flex",
        flexDirection: "column",
        gap: "7px",
      },
      detailSection: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "7px",
      },
      flexWrapSection: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "7px",
      },
      flexWrapContainers: {
        width: "47%",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      },
      title: {
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        backgroundColor: "#0d111a",
        color: "white",
        fontSize: "10px",
      },
      subheadings: {
        fontFamily: "Roboto-semi",
        fontSize: "11px",
      },
      secondSectionBox: {
        width: " 100%",
        display: " flex",
        flexDirection: "column",
        gap: "4px",
        alignItems: "flex-start",
      },
      mapData: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "7px",
      },
      semiBoldText: {
        fontFamily: "Roboto-semi",
      },
      linkText: {
        textDecoration: "underline",
        cursor: "pointer",
        fontSize: "10px",
        color: "#0d111a",
      },
      skillsText: {
        borderBottom: "2px",
        borderColor: "#0d111a",
        width: "48%",
        fontFamily: "Roboto-semi",
      },
    };
    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            {imageValue ? (
              <View style={styles.heroSectionImage}>
                <View style={styles.imgSection}>
                  <Image src={imageValue} style={styles.img} />
                </View>
                <View style={styles.textSectionImage}>
                  {personalInfoValues.firstName ||
                  personalInfoValues.lastName ? (
                    <View>
                      <Text style={styles.name}>
                        {personalInfoValues.firstName}
                      </Text>
                      <Text style={styles.name}>
                        {personalInfoValues.lastName}
                      </Text>
                      <Text style={styles.jobtitle}>
                        {personalInfoValues.jobTitle}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : (
              <View style={styles.heroSectionWithoutImage}>
                <View style={styles.namePortionWithoutImg}>
                  {personalInfoValues.firstName ||
                  personalInfoValues.lastName ? (
                    <View style={styles.nameDiv}>
                      <Text style={styles.nameWithoutImg}>
                        {personalInfoValues.firstName}
                      </Text>
                      <Text style={styles.nameWithoutImg}>
                        {personalInfoValues.lastName}
                      </Text>
                    </View>
                  ) : null}
                  <View style={styles.textSectionWithoutImage}>
                    {personalInfoValues.jobTitle ? (
                      <Text style={styles.jobtitle}>
                        {personalInfoValues.jobTitle}
                      </Text>
                    ) : null}
                    {personalInfoValues.address ||
                    personalInfoValues.city ||
                    personalInfoValues.postalcode ||
                    personalInfoValues.country ? (
                      <Text>
                        {personalInfoValues.address} {personalInfoValues.city}{" "}
                        {personalInfoValues.postalcode}{" "}
                        {personalInfoValues.country}
                      </Text>
                    ) : null}
                    {personalInfoValues.email ? (
                      <Text>{personalInfoValues.email}</Text>
                    ) : null}
                    {personalInfoValues.phoneNumber ? (
                      <Text>{personalInfoValues.phoneNumber}</Text>
                    ) : null}
                  </View>
                </View>
              </View>
            )}
            <View style={styles.secondSectionMainContainer}>
              <View style={styles.sectionSectionChildContainer}>
                {imageValue ? (
                  <View style={styles.detailSection}>
                    <Text style={styles.title}>DETAILS</Text>
                    <View style={styles.flexWrapSection}>
                      {personalInfoValues.address ||
                      personalInfoValues.city ||
                      personalInfoValues.postalcode ||
                      personalInfoValues.country ||
                      personalInfoValues.email ||
                      personalInfoValues.phoneNumber ? (
                        <View style={styles.flexWrapContainers}>
                          <Text style={styles.subheadings}>Contact</Text>
                          {personalInfoValues.address ||
                          personalInfoValues.city ||
                          personalInfoValues.postalcode ||
                          personalInfoValues.country ? (
                            <Text>
                              {personalInfoValues.address}{" "}
                              {personalInfoValues.city}{" "}
                              {personalInfoValues.postalcode}{" "}
                              {personalInfoValues.country}
                            </Text>
                          ) : null}
                          {personalInfoValues.email ? (
                            <Text>{personalInfoValues.email}</Text>
                          ) : null}
                          {personalInfoValues.phoneNumber ? (
                            <Text>{personalInfoValues.phoneNumber}</Text>
                          ) : null}
                        </View>
                      ) : null}
                      {personalInfoValues.drivinglicense ? (
                        <View style={styles.flexWrapContainers}>
                          <Text style={styles.subheadings}>
                            Driving License
                          </Text>
                          <Text>{personalInfoValues.drivinglicense}</Text>
                        </View>
                      ) : null}
                      {personalInfoValues.nationality ? (
                        <View style={styles.flexWrapContainers}>
                          <Text style={styles.subheadings}>Nationality</Text>
                          <Text>{personalInfoValues.nationality}</Text>
                        </View>
                      ) : null}
                      {personalInfoValues.dateofbirth ||
                      personalInfoValues.placeofbirth ? (
                        <View style={styles.flexWrapContainers}>
                          <Text style={styles.subheadings}>
                            Date / Place of Birth
                          </Text>
                          <Text>
                            {personalInfoValues.dateofbirth} /{" "}
                            {personalInfoValues.placeofbirth}
                          </Text>
                        </View>
                      ) : null}
                    </View>
                  </View>
                ) : (
                  <View>
                    {personalInfoValues.drivinglicense ||
                    personalInfoValues.nationality ||
                    personalInfoValues.dateofbirth ||
                    personalInfoValues.placeofbirth ? (
                      <View style={styles.detailSection}>
                        <Text style={styles.title}>DETAILS</Text>
                        <View style={styles.flexWrapSection}>
                          {personalInfoValues.drivinglicense ? (
                            <View style={styles.flexWrapContainers}>
                              <Text style={styles.subheadings}>
                                Driving License
                              </Text>
                              <Text>{personalInfoValues.drivinglicense}</Text>
                            </View>
                          ) : null}
                          {personalInfoValues.nationality ? (
                            <View style={styles.flexWrapContainers}>
                              <Text style={styles.subheadings}>
                                Nationality
                              </Text>
                              <Text>{personalInfoValues.nationality}</Text>
                            </View>
                          ) : null}
                          {personalInfoValues.dateofbirth ||
                          personalInfoValues.placeofbirth ? (
                            <View style={styles.flexWrapContainers}>
                              <Text style={styles.subheadings}>
                                Date / Place of Birth
                              </Text>
                              <Text>
                                {personalInfoValues.dateofbirth} /{" "}
                                {personalInfoValues.placeofbirth}
                              </Text>
                            </View>
                          ) : null}
                        </View>
                      </View>
                    ) : null}
                  </View>
                )}

                {editorValue ? (
                  <View style={styles.secondSectionBox}>
                    <Text style={styles.title}>PROFILE</Text>
                    <Text>{editorValue}</Text>
                  </View>
                ) : null}
                {personalInfoValues.clearance ? (
                  <View style={styles.secondSectionBox}>
                    <Text style={styles.title}>CLEARANCE</Text>
                    <Text>{personalInfoValues.clearance}</Text>
                  </View>
                ) : null}

                {employementData[0] ? (
                  <View style={styles.secondSectionBox}>
                    <Text style={styles.title}>EMPLOYMENT HISTORY</Text>
                    <View style={styles.mapData}>
                      {employementData.map((item, index) => {
                        return (
                          <View key={index} style={styles.secondSectionBox}>
                            <Text style={styles.semiBoldText}>
                              {item.jobtitle1} {item.employer} {item.jobcity}
                            </Text>
                            <Text>
                              {item.jobstart} {item.jobend}
                            </Text>
                            <Text>{item.jobdescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}

                {educationData[0] ? (
                  <View style={styles.secondSectionBox}>
                    <Text style={styles.title}>EDUCATION</Text>
                    <View style={styles.mapData}>
                      {educationData.map((item, index) => {
                        return (
                          <View key={index} style={styles.secondSectionBox}>
                            <Text style={styles.semiBoldText}>
                              {item.degree} {item.school} {item.city1}
                            </Text>
                            <Text>
                              {item.start} {item.end}
                            </Text>
                            <Text>{item.edudescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}
                {websiteData[0] ? (
                  <View style={styles.secondSectionBox}>
                    <Text style={styles.title}>LINKS</Text>
                    <View style={styles.flexWrapSection}>
                      {websiteData.map((item, index) => {
                        return (
                          <Link
                            style={styles.linkText}
                            key={index}
                            src={item.link}
                          >
                            {item.label}
                          </Link>
                        );
                      })}
                    </View>
                  </View>
                ) : null}
                {skillsData[0] ? (
                  <View style={styles.secondSectionBox}>
                    <Text style={styles.title}>SKILLS</Text>
                    <View style={styles.flexWrapSection}>
                      {skillsData.map((item, index) => {
                        return (
                          <Text key={index} style={styles.skillsText}>
                            {item}
                          </Text>
                        );
                      })}
                    </View>
                  </View>
                ) : null}

                {customSectionData[0] ? (
                  <View style={styles.secondSectionBox}>
                    <View style={styles.mapData}>
                      {customSectionData.map((item, index) => {
                        return (
                          <View key={index} style={styles.secondSectionBox}>
                            <Text style={styles.title}>
                              {item.sectionTitle}
                            </Text>
                            <Text style={styles.semiBoldText}>
                              {item.activity} {item.customSectionCity}
                            </Text>
                            <Text>
                              {item.customStartDate} {item.customEndDate}
                            </Text>
                            <Text>{item.customDescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}
              </View>
            </View>
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center justify-between">
      <div
        ref={containerRef}
        className="flex flex-col max-[1100px]:mt-0 w-full overflow-y-scroll hide-scrollbar max-[650px]:w-full h-full"
      >
        {imageValue ? (
          <div className="w-full flex gap-[5px]">
            <div className=" w-[30%] ">
              <img
                src={imageValue}
                alt="profile"
                className="w-full h-[150px]"
              />
            </div>
            <div className="w-[70%] p-[30px] bg-[#ffe14c] justify-center text-[9px] flex flex-col gap-[7px] text-[#0d111a]">
              {personalInfoValues.firstName || personalInfoValues.lastName ? (
                <>
                  <h2 className="text-[18px] font-semibold leading-[1.3em]">
                    {personalInfoValues.firstName}
                    <br />
                    {personalInfoValues.lastName}
                  </h2>
                  <p className="font-medium">{personalInfoValues.jobTitle}</p>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="w-full p-[30px] bg-[#ffe14c] text-[9px] flex flex-col gap-[7px] text-[#0d111a]">
            <div className="flex items-center gap-[30px] justify-between">
              {personalInfoValues.firstName || personalInfoValues.lastName ? (
                <div className="w-[50%]">
                  <h2 className="text-[25px] font-semibold leading-[1.3em]">
                    {personalInfoValues.firstName}
                    <br />
                    {personalInfoValues.lastName}
                  </h2>
                </div>
              ) : null}

              <div className="flex flex-col text-[11px] w-[50%] gap-[7px]">
                {personalInfoValues.jobTitle ? (
                  <p className="font-medium">{personalInfoValues.jobTitle}</p>
                ) : null}
                {personalInfoValues.address ||
                personalInfoValues.city ||
                personalInfoValues.postalcode ||
                personalInfoValues.country ? (
                  <p>
                    {personalInfoValues.address} {personalInfoValues.city}{" "}
                    {personalInfoValues.postalcode} {personalInfoValues.country}
                  </p>
                ) : null}
                {personalInfoValues.email ? (
                  <p>{personalInfoValues.email}</p>
                ) : null}
                {personalInfoValues.phoneNumber ? (
                  <p>{personalInfoValues.phoneNumber}</p>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <div className="w-full flex p-[25px] text-[7px] sm:text-[10px] justify-center bg-white">
          <div className="sm:w-[80%] flex flex-col gap-[7px]">
            {imageValue ? (
              <div className="w-full flex flex-col  items-start gap-[7px]">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  DETAILS
                </h3>
                <div className="w-full flex gap-[7px] flex-wrap">
                  {personalInfoValues.address ||
                  personalInfoValues.city ||
                  personalInfoValues.postalcode ||
                  personalInfoValues.country ||
                  personalInfoValues.email ||
                  personalInfoValues.phoneNumber ? (
                    <div className="w-[47%] flex flex-col gap-[4px]">
                      <h4 className="font-semibold text-[9px] sm:text-[11px]">
                        Contact
                      </h4>
                      {personalInfoValues.address ||
                      personalInfoValues.city ||
                      personalInfoValues.postalcode ||
                      personalInfoValues.country ? (
                        <p>
                          {personalInfoValues.address} {personalInfoValues.city}{" "}
                          {personalInfoValues.postalcode}{" "}
                          {personalInfoValues.country}
                        </p>
                      ) : null}
                      {personalInfoValues.email ? (
                        <p>{personalInfoValues.email}</p>
                      ) : null}
                      {personalInfoValues.phoneNumber ? (
                        <p>{personalInfoValues.phoneNumber}</p>
                      ) : null}
                    </div>
                  ) : null}
                  {personalInfoValues.drivinglicense ? (
                    <div className="w-[47%] flex flex-col gap-[4px]">
                      <h4 className="font-semibold text-[9px] sm:text-[11px]">
                        Driving license
                      </h4>
                      <p>{personalInfoValues.drivinglicense}</p>
                    </div>
                  ) : null}
                  {personalInfoValues.nationality ? (
                    <div className="w-[47%] flex flex-col gap-[4px]">
                      <h4 className="font-semibold text-[9px] sm:text-[11px]">
                        Nationality
                      </h4>
                      <p>{personalInfoValues.nationality}</p>
                    </div>
                  ) : null}
                  {personalInfoValues.dateofbirth ||
                  personalInfoValues.placeofbirth ? (
                    <div className="w-[47%] flex flex-col gap-[4px]">
                      <h4 className="font-semibold text-[9px] sm:text-[11px]">
                        Date / Place of Birth
                      </h4>
                      <p>
                        {personalInfoValues.dateofbirth} /{" "}
                        {personalInfoValues.placeofbirth}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <>
                {personalInfoValues.drivinglicense ||
                personalInfoValues.nationality ||
                personalInfoValues.dateofbirth ||
                personalInfoValues.placeofbirth ? (
                  <div className="w-full flex flex-col  items-start gap-[7px]">
                    <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                      DETAILS
                    </h3>
                    <div className="w-full flex gap-[7px] flex-wrap">
                      {personalInfoValues.drivinglicense ? (
                        <div className="w-[47%] flex flex-col gap-[4px]">
                          <h4 className="font-semibold text-[11px]">
                            Driving license
                          </h4>
                          <p>{personalInfoValues.drivinglicense}</p>
                        </div>
                      ) : null}
                      {personalInfoValues.nationality ? (
                        <div className="w-[47%] flex flex-col gap-[4px]">
                          <h4 className="font-semibold text-[11px]">
                            Nationality
                          </h4>
                          <p>{personalInfoValues.nationality}</p>
                        </div>
                      ) : null}
                      {personalInfoValues.dateofbirth ||
                      personalInfoValues.placeofbirth ? (
                        <div className="w-[47%] flex flex-col gap-[4px]">
                          <h4 className="font-semibold text-[11px]">
                            Date / Place of Birth
                          </h4>
                          <p>
                            {personalInfoValues.dateofbirth} /{" "}
                            {personalInfoValues.placeofbirth}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </>
            )}

            {editorValue ? (
              <div className="w-full flex flex-col gap-[4px] items-start">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  PROFILE
                </h3>
                <p>{editorValue}</p>
              </div>
            ) : null}
            {personalInfoValues.clearance ? (
              <div className="w-full flex flex-col gap-[4px] items-start">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  CLEARANCE
                </h3>
                <p>{personalInfoValues.clearance}</p>
              </div>
            ) : null}

            {employementData[0] ? (
              <div className="w-full flex flex-col gap-[4px] items-start">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  EMPLOYMENT HISTORY
                </h3>
                <div className="w-full flex flex-col gap-[7px]">
                  {employementData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full flex flex-col gap-[4px]"
                      >
                        <p className="font-semibold">
                          {item.jobtitle1} {item.employer} {item.jobcity}
                        </p>
                        <p>
                          {item.jobstart} {item.jobend}
                        </p>
                        <p>{item.jobdescription}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {educationData[0] ? (
              <div className="w-full flex flex-col gap-[4px] items-start">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  EDUCATION
                </h3>
                <div className="w-full flex flex-col gap-[7px]">
                  {educationData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full flex flex-col gap-[4px]"
                      >
                        <p className="font-semibold">
                          {item.degree} {item.school} {item.city1}
                        </p>
                        <p>
                          {item.start} {item.end}
                        </p>
                        <p>{item.edudescription}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {websiteData[0] ? (
              <div className="w-full flex flex-col gap-[4px] items-start">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  LINKS
                </h3>
                <div className="flex gap-[7px] items-center w-full flex-wrap">
                  {websiteData.map((item, index) => {
                    return (
                      <h3
                        key={index}
                        onClick={() => window.open(`//${item.link}`, "_blank")}
                        className="underline cursor-pointer text-[9px] max-[650px]:text-[7px]"
                      >
                        {item.label}
                      </h3>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {skillsData[0] ? (
              <div className="w-full flex flex-col gap-[4px] items-start">
                <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                  SKILLS
                </h3>
                <div className="flex w-full gap-[7px] items-center flex-wrap">
                  {skillsData.map((item, index) => {
                    return (
                      <p
                        key={index}
                        className="border-b-2  border-[#303030] w-[48%] font-medium"
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {customSectionData[0] ? (
              <div className="w-full my-1 flex flex-col gap-[4px]">
                {customSectionData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full items-start flex flex-col gap-[4px]"
                    >
                      <h3 className="py-[5px] px-[10px] bg-[#0d111a] text-white text-[10px]">
                        {item.sectionTitle}
                      </h3>
                      <p className="font-semibold">
                        {item.activity} {item.customSectionCity}
                      </p>
                      <p>
                        {item.customStartDate} {item.customEndDate}
                      </p>
                      <p>{item.customDescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        <PDFDownloadLink
          document={<Resume4Download />}
          style={{
            textDecoration: "none",
            padding: "5px 8px",
            color: "black",
            fontWeight: 500,
            backgroundColor: "white",
            border: "1px solid #4A90E2",
            borderRadius: "4px",
            margin: "10px 0",
          }}
        >
          Download PDF
        </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume4Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Resume4;
