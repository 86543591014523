import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  PDFDownloadLink,
  Font,
  Image,
} from "@react-pdf/renderer";
import { Provider } from "react-redux";
import store from "../store";
import exportAsImage from "./resumecontrolbar";
import { setResumeImage5 } from "../state/reducers/resumeimageSlice";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";
import { generate } from "./resume5Word";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import Location from "../assets/icons/location1.png";
import Phone from "../assets/icons/phone1.png";
import Dot from "../assets/icons/dot.png";
import User from "../assets/icons/user.png";
import Job from "../assets/icons/job.png";
import Line from "../assets/icons/line.png";
import Security from "../assets/icons/security.png";
import Star from "../assets/icons/star.png";
import Education from "../assets/icons/education.png";
const Resume5 = () => {
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  const dispatch = useDispatch();
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage5(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [3000]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const imageValue = useSelector((state) => state.image.imageValue);

  const navigate = useNavigate();
  const Resume5Download = () => {
    Font.register({
      family: "Roboto-regular",
      src: require("../fonts/Roboto/Roboto-Regular.ttf"),
    });
    Font.register({
      family: "Roboto-semi",
      src: require("../fonts/Roboto/Roboto-Medium.ttf"),
    });

    Font.register({
      family: "Roboto-bold",
      src: require("../fonts/Roboto/Roboto-Bold.ttf"),
    });

    const styles = {
      container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        fontSize: "9px",
        padding: "20px",
      },
      mainImg: {
        width: "70px",
        height: "70px",
        borderRadius: "12px",
      },
      name: {
        fontSize: "18px",
        fontFamily: "Roboto-semi",
      },
      flexRowContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "7px",
        borderBottom: "1px",
        borderColor: "black",
        paddingBottom: "8px",
      },
      flexRowBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
      },
      childImg: {
        width: "7px",
      },
      mainSection: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "25px",
      },
      detailsContainer: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
      },
      detailsChildContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        marginBottom: "5px",
      },
      detailsChildContainer1: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: "2px",
      },
      detailSectionImg: {
        width: "12px",
      },
      detailsTitle: {
        fontSize: "11px",
        fontFamily: "Roboto-semi",
      },
      detailsSubHeadings: {
        color: "black",
        fontFamily: "Roboto-semi",
        fontSize: "10px",
      },
      detailsDataFlex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: "2px",
      },
      detailsData: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        alignItems: "start",
      },
      fullWidthDiv: {
        width: "100%",
      },
      skillsDiv: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        alignItems: "center",
      },
      links: {
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "9px",
        color: "black",
      },
      skills: {
        paddingLeft: "5px",
        paddingRight: "5px",
      },
      secondSection: {
        width: "70%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
      secondSectionFlexBox: {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
      },
      secondSectionFlexBox1: {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
        marginBottom: "4px",
      },
      mapContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      },
      mapDiv: {
        display: "flex",
        flexDirection: "column",
        gap: "7px",
      },
      mapDataBox: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        borderLeft: "2px",
        borderColor: "black",
        marginLeft: "4px",
        paddingLeft: "7px",
      },
      mapBoldText: {
        fontFamily: "Roboto-semi",
      },
      mapRegularText: {
        color: "#7b869a",
        fontFamily: "Roboto-semi",
      },
    };
    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            {imageValue ? (
              <Image src={imageValue} style={styles.mainImg} />
            ) : null}
            {personalInfoValues.firstName || personalInfoValues.lastName ? (
              <Text style={styles.name}>
                {personalInfoValues.firstName} {personalInfoValues.lastName}
              </Text>
            ) : null}
            <View style={styles.flexRowContainer}>
              {personalInfoValues.jobTitle ? (
                <Text>{personalInfoValues.jobTitle}</Text>
              ) : null}
              {personalInfoValues.city ||
              personalInfoValues.postalcode ||
              personalInfoValues.country ? (
                <View style={styles.flexRowBox}>
                  <Image src={Location} style={styles.childImg} />
                  <Text>
                    {personalInfoValues.city} {personalInfoValues.postalcode}{" "}
                    {personalInfoValues.country}
                  </Text>
                </View>
              ) : null}
              {personalInfoValues.phoneNumber ? (
                <View style={styles.flexRowBox}>
                  <Image src={Phone} style={styles.childImg} />
                  <Text>{personalInfoValues.phoneNumber}</Text>
                </View>
              ) : null}
            </View>
            <View style={styles.mainSection}>
              <View style={styles.detailsContainer}>
                {personalInfoValues.address ||
                personalInfoValues.city ||
                personalInfoValues.postalcode ||
                personalInfoValues.country ||
                personalInfoValues.phoneNumber ||
                personalInfoValues.email ||
                personalInfoValues.dateofbirth ||
                personalInfoValues.placeofbirth ||
                personalInfoValues.nationality ||
                personalInfoValues.drivinglicense ? (
                  <View style={styles.fullWidthDiv}>
                    <View style={styles.detailsChildContainer}>
                      <Image src={Dot} style={styles.detailSectionImg} />
                      <Text style={styles.detailsTitle}>DETAILS</Text>
                      <Image src={Dot} style={styles.detailSectionImg} />
                    </View>
                    <View style={styles.detailsData}>
                      {personalInfoValues.address ||
                      personalInfoValues.city ||
                      personalInfoValues.postalcode ||
                      personalInfoValues.country ? (
                        <View style={styles.detailsData}>
                          <Text style={styles.detailsSubHeadings}>Address</Text>
                          <View style={styles.detailsDataFlex}>
                            <Image src={Dot} style={styles.detailSectionImg} />
                            <Text>
                              {personalInfoValues.address}{" "}
                              {personalInfoValues.city}{" "}
                              {personalInfoValues.postalcode}{" "}
                              {personalInfoValues.country}
                            </Text>
                          </View>
                        </View>
                      ) : null}

                      {personalInfoValues.phoneNumber ? (
                        <View style={styles.detailsData}>
                          <Text style={styles.detailsSubHeadings}>Phone</Text>
                          <View style={styles.detailsDataFlex}>
                            <Image src={Dot} style={styles.detailSectionImg} />
                            <Text>{personalInfoValues.phoneNumber}</Text>
                          </View>
                        </View>
                      ) : null}
                      {personalInfoValues.email ? (
                        <View style={styles.detailsData}>
                          <Text style={styles.detailsSubHeadings}>Email</Text>
                          <View style={styles.detailsDataFlex}>
                            <Image src={Dot} style={styles.detailSectionImg} />
                            <Text>{personalInfoValues.email}</Text>
                          </View>
                        </View>
                      ) : null}
                    </View>
                  </View>
                ) : null}
                {personalInfoValues.dateofbirth ||
                personalInfoValues.placeofbirth ? (
                  <View style={styles.detailsData}>
                    <Text style={styles.detailsSubHeadings}>
                      Date | Place of Birth
                    </Text>
                    <View style={styles.detailsDataFlex}>
                      <Image src={Dot} style={styles.detailSectionImg} />
                      <Text>
                        {personalInfoValues.dateofbirth} |{" "}
                        {personalInfoValues.placeofbirth}
                      </Text>
                    </View>
                  </View>
                ) : null}

                {personalInfoValues.nationality ? (
                  <View style={styles.detailsData}>
                    <Text style={styles.detailsSubHeadings}>Nationality</Text>
                    <View style={styles.detailsDataFlex}>
                      <Image src={Dot} style={styles.detailSectionImg} />
                      <Text>{personalInfoValues.nationality}</Text>
                    </View>
                  </View>
                ) : null}
                {personalInfoValues.drivinglicense ? (
                  <View style={styles.detailsData}>
                    <Text style={styles.detailsSubHeadings}>
                      Driving License
                    </Text>
                    <View style={styles.detailsDataFlex}>
                      <Image src={Dot} style={styles.detailSectionImg} />
                      <Text>{personalInfoValues.drivinglicense}</Text>
                    </View>
                  </View>
                ) : null}

                {websiteData[0] ? (
                  <View style={styles.skillsDiv}>
                    <View style={styles.detailsChildContainer1}>
                      <Text style={styles.detailsTitle}>LINKS</Text>
                    </View>
                    <View style={styles.skillsDiv}>
                      {websiteData.map((item, index) => {
                        return (
                          <View key={index} style={styles.detailsDataFlex}>
                            <Image src={Dot} style={styles.detailSectionImg} />
                            <Link src={item.link} style={styles.links}>
                              {item.label}
                            </Link>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}

                {skillsData[0] ? (
                  <View style={styles.skillsDiv}>
                    <View style={styles.detailsChildContainer1}>
                      <Text style={styles.detailsTitle}>SKILLS</Text>
                    </View>
                    <View style={styles.skillsDiv}>
                      {skillsData.map((item, index) => {
                        return (
                          <View key={index} style={styles.detailsDataFlex}>
                            <Image src={Dot} style={styles.detailSectionImg} />
                            <Text style={styles.skills}>{item}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}
              </View>
              <View style={styles.secondSection}>
                {editorValue ? (
                  <View style={styles.mapContainer}>
                    <View style={styles.secondSectionFlexBox}>
                      <Image src={User} style={styles.detailSectionImg} />
                      <Text style={styles.detailsTitle}>PROFILE</Text>
                    </View>
                    <Text>{editorValue}</Text>
                  </View>
                ) : null}
                {personalInfoValues.clearance ? (
                  <View style={styles.mapContainer}>
                    <View style={styles.secondSectionFlexBox}>
                      <Image src={Security} style={styles.detailSectionImg} />
                      <Text style={styles.detailsTitle}>CLEARANCE</Text>
                    </View>
                    <Text>{personalInfoValues.clearance}</Text>
                  </View>
                ) : null}

                {employementData[0] ? (
                  <View style={styles.mapContainer}>
                    <View style={styles.secondSectionFlexBox}>
                      <Image src={Job} style={styles.detailSectionImg} />
                      <Text style={styles.detailsTitle}>
                        EMPLOYMENT HISTORY
                      </Text>
                    </View>
                    <View style={styles.mapDiv}>
                      {employementData.map((item, index) => {
                        return (
                          <View key={index} style={styles.mapDataBox}>
                            <Text style={styles.mapBoldText}>
                              {item.jobtitle1} at {item.employer},{" "}
                              {item.jobcity}
                            </Text>
                            <Text style={styles.mapRegularText}>
                              {item.jobstart} {item.jobend}
                            </Text>
                            <Text>{item.jobdescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}
                {educationData[0] ? (
                  <View style={styles.mapContainer}>
                    <View style={styles.secondSectionFlexBox}>
                      <Image src={Education} style={styles.detailSectionImg} />
                      <Text style={styles.detailsTitle}>EDUCATION</Text>
                    </View>
                    <View style={styles.mapDiv}>
                      {educationData.map((item, index) => {
                        return (
                          <View key={index} style={styles.mapDataBox}>
                            <Text style={styles.mapBoldText}>
                              {item.degree}, {item.school}, {item.city1}
                            </Text>
                            <Text style={styles.mapRegularText}>
                              {item.start} {item.end}
                            </Text>
                            <Text>{item.edudescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                ) : null}

                {customSectionData[0] ? (
                  <View style={styles.mapContainer}>
                    {customSectionData.map((item, index) => {
                      return (
                        <View key={index}>
                          <View style={styles.secondSectionFlexBox1}>
                            <Image src={Star} style={styles.detailSectionImg} />
                            <Text style={styles.detailsTitle}>
                              {item.sectionTitle}
                            </Text>
                          </View>
                          <View style={styles.mapDataBox}>
                            <Text style={styles.mapBoldText}>
                              {item.activity}, {item.customSectionCity}
                            </Text>
                            <Text style={styles.mapRegularText}>
                              {item.customStartDate} {item.customEndDate}
                            </Text>
                            <Text>{item.customDescription}</Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                ) : null}
              </View>
            </View>
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center justify-between ">
      <div
        ref={containerRef}
        className="flex flex-col overflow-y-scroll hide-scrollbar max-[1100px]:mt-0 w-full max-[650px]:w-full h-full p-[20px] text-[7px] sm:text-[9px]"
      >
        <div className="w-full flex flex-col gap-[10px] items-center">
          {imageValue ? (
            <img
              src={imageValue}
              alt="profile"
              className="w-[70px] h-[70px] rounded-[12px]"
            />
          ) : null}
          {personalInfoValues.firstName || personalInfoValues.lastName ? (
            <h2 className="text-[12px] sm:text-[18px] font-semibold">
              {personalInfoValues.firstName} {personalInfoValues.lastName}
            </h2>
          ) : null}
          <div className="w-full flex items-center border-b font-semibold border-black pb-2 justify-center gap-[7px]">
            {personalInfoValues.jobTitle ? (
              <p>{personalInfoValues.jobTitle}</p>
            ) : null}
            {personalInfoValues.city ||
            personalInfoValues.postalcode ||
            personalInfoValues.country ? (
              <div className="flex gap-[5px] items-center">
                <img src={Location} alt="location" className="w-[7px]" />
                <p>
                  {personalInfoValues.city} {personalInfoValues.postalcode}{" "}
                  {personalInfoValues.country}
                </p>
              </div>
            ) : null}
            {personalInfoValues.phoneNumber ? (
              <div className="flex gap-[5px] items-center">
                <img src={Phone} alt="phone" className="w-[7px]" />
                <p>{personalInfoValues.phoneNumber}</p>
              </div>
            ) : null}
          </div>
          <div className="w-full flex gap-[20px]">
            <div className="w-[30%] flex flex-col items-start gap-[5px]">
              {personalInfoValues.address ||
              personalInfoValues.city ||
              personalInfoValues.postalcode ||
              personalInfoValues.country ||
              personalInfoValues.phoneNumber ||
              personalInfoValues.email ||
              personalInfoValues.dateofbirth ||
              personalInfoValues.placeofbirth ||
              personalInfoValues.nationality ||
              personalInfoValues.drivinglicense ? (
                <>
                  <div className="w-full flex justify-center items-center gap-[2px]">
                    <img src={Dot} alt="dot" className="w-[12px]" />
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      DETAILS
                    </h3>
                    <img src={Dot} alt="dot" className="w-[12px]" />
                  </div>
                  <div className="flex flex-col w-full items-start gap-[2px]">
                    {personalInfoValues.address ||
                    personalInfoValues.city ||
                    personalInfoValues.postalcode ||
                    personalInfoValues.country ? (
                      <div className="w-full flex flex-col gap-[2px] items-start">
                        <h3 className="font-semibold text-[9px] sm:text-[10px]">
                          Address
                        </h3>
                        <div className="w-full flex justify-start items-start gap-[2px]">
                          <img src={Dot} alt="dot" className="w-[12px]" />
                          <p>
                            {personalInfoValues.address}{" "}
                            {personalInfoValues.city}{" "}
                            {personalInfoValues.postalcode}{" "}
                            {personalInfoValues.country}
                          </p>
                        </div>
                      </div>
                    ) : null}

                    {personalInfoValues.phoneNumber ? (
                      <div className="w-full flex flex-col gap-[2px] items-start">
                        <h3 className="font-semibold text-[9px] sm:text-[10px]">
                          Phone
                        </h3>
                        <div className="w-full flex justify-start items-start gap-[2px]">
                          <img src={Dot} alt="dot" className="w-[12px]" />
                          <p>{personalInfoValues.phoneNumber}</p>
                        </div>
                      </div>
                    ) : null}

                    {personalInfoValues.email ? (
                      <div className="w-full flex flex-col gap-[2px] items-start">
                        <h3 className="font-semibold text-[9px] sm:text-[10px]">
                          Email
                        </h3>
                        <div className="w-full flex justify-start items-start gap-[2px]">
                          <img src={Dot} alt="dot" className="w-[12px]" />
                          <p>{personalInfoValues.email}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
              {personalInfoValues.dateofbirth ||
              personalInfoValues.placeofbirth ? (
                <div className="w-full flex flex-col items-start gap-[2px]">
                  <h4 className="text-black font-semibold text-[9px] sm:text-[10px]">
                    Date | Place of Birth
                  </h4>
                  <div className="w-full flex justify-start items-start gap-[2px]">
                    <img src={Dot} alt="dot" className="w-[12px]" />
                    <p>
                      {personalInfoValues.dateofbirth} |{" "}
                      {personalInfoValues.placeofbirth}
                    </p>
                  </div>
                </div>
              ) : null}
              {personalInfoValues.nationality ? (
                <div className="w-full flex flex-col items-start gap-[2px]">
                  <h4 className="text-black font-semibold text-[9px] sm:text-[10px]">
                    Nationality
                  </h4>
                  <div className="w-full flex justify-start items-start gap-[2px]">
                    <img src={Dot} alt="dot" className="w-[12px]" />
                    <p>{personalInfoValues.nationality}</p>
                  </div>
                </div>
              ) : null}
              {personalInfoValues.drivinglicense ? (
                <div className="w-full flex flex-col items-start gap-[2px]">
                  <h4 className="text-black font-semibold text-[9px] sm:text-[10px]">
                    Driving License
                  </h4>
                  <div className="w-full flex justify-start items-start gap-[2px]">
                    <img src={Dot} alt="dot" className="w-[12px]" />
                    <p>{personalInfoValues.drivinglicense}</p>
                  </div>
                </div>
              ) : null}
              {websiteData[0] ? (
                <div className="w-full flex flex-col gap-[5px] items-start">
                  <div className="w-full flex justify-start items-center gap-[2px]">
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      LINKS
                    </h3>
                  </div>
                  <div className="flex flex-col gap-[5px] items-start w-full">
                    {websiteData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full flex justify-start items-start gap-[2px]"
                        >
                          <img src={Dot} alt="dot" className="w-[12px]" />
                          <h3
                            onClick={() =>
                              window.open(`//${item.link}`, "_blank")
                            }
                            className="cursor-pointer text-[9px]"
                          >
                            {item.label}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {skillsData[0] ? (
                <div className="w-full flex flex-col gap-[5px] items-start">
                  <div className="w-full flex justify-start items-center gap-[2px]">
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      SKILLS
                    </h3>
                  </div>
                  <div className="flex flex-col gap-[5px] items-start w-full">
                    {skillsData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full flex justify-start items-start gap-[2px]"
                        >
                          <img src={Dot} alt="dot" className="w-[12px]" />
                          <p>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="w-[70%] flex flex-col gap-[10px]">
              {editorValue ? (
                <div className="flex flex-col gap-[5px]">
                  <div className="flex gap-[5px] items-center">
                    <img src={User} alt="dot" className="w-[12px]" />
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      PROFILE
                    </h3>
                  </div>
                  <p>{editorValue}</p>
                </div>
              ) : null}
              {personalInfoValues.clearance ? (
                <div className="flex flex-col gap-[5px]">
                  <div className="flex gap-[5px] items-center">
                    <img src={Security} alt="dot" className="w-[12px]" />
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      CLEARANCE
                    </h3>
                  </div>
                  <p>{personalInfoValues.clearance}</p>
                </div>
              ) : null}
              {employementData[0] ? (
                <div className="flex flex-col gap-[5px]">
                  <div className="flex gap-[5px] items-center">
                    <img src={Job} alt="job-icon" className="w-[12px]" />
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      EMPLOYMENT HISTORY
                    </h3>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    {employementData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col gap-[2px] border-l-2 border-black ml-1 pl-[7px]"
                        >
                          <p className="font-semibold">
                            {item.jobtitle1} at {item.employer}, {item.jobcity}
                          </p>
                          <p className="text-[#7b869a]">
                            {item.jobstart} {item.jobend}
                          </p>
                          <p>{item.jobdescription}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {educationData[0] ? (
                <div className="flex flex-col gap-[5px]">
                  <div className="flex gap-[5px] items-center">
                    <img src={Education} alt="job-icon" className="w-[12px]" />
                    <h3 className="font-semibold text-[10px] sm:text-[12px]">
                      EDUCATION
                    </h3>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    {educationData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col gap-[2px] border-l-2 border-black ml-1 pl-[7px]"
                        >
                          <p className="font-semibold">
                            {item.degree}, {item.school}, {item.city1}
                          </p>
                          <p className="text-[#7b869a]">
                            {item.start} {item.end}
                          </p>
                          <p>{item.edudescription}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {customSectionData[0] ? (
                <div className="flex flex-col gap-[5px]">
                  {customSectionData.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="flex gap-[5px] mb-1 items-center">
                          <img src={Star} alt="job-icon" className="w-[12px]" />
                          <h3 className="font-semibold text-[10px] sm:text-[12px]">
                            {item.sectionTitle}
                          </h3>
                        </div>
                        <div className="flex flex-col gap-[2px] border-l-2 border-black ml-1 pl-[7px]">
                          <p className="font-semibold">
                            {item.activity}, {item.customSectionCity}
                          </p>
                          <p className="text-[#7b869a]">
                            {item.customStartDate} {item.customEndDate}
                          </p>
                          <p>{item.customDescription}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        <PDFDownloadLink
          document={<Resume5Download />}
          style={{
            textDecoration: "none",
            padding: "5px 8px",
            color: "black",
            fontWeight: 500,
            backgroundColor: "white",
            border: "1px solid #4A90E2",
            borderRadius: "4px",
            margin: "10px 0",
          }}
        >
          Download PDF
        </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume5Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Resume5;
