import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { Provider } from "react-redux";
import store from "../store";
import exportAsImage from "./resumecontrolbar";
import { setResumeImage5 } from "../state/reducers/resumeimageSlice";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";

const Resume10 = () => {
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  const dispatch = useDispatch();
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage5(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [3000]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const navigate = useNavigate();
  const Resume10Download = () => {
    Font.register({
      family: "Poppins-semi",
      src: require("../fonts/Poppins/Poppins-SemiBold.ttf"), // Make sure to provide the correct path to your font file.
    });

    Font.register({
      family: "Poppins-bold",
      src: require("../fonts/Poppins/Poppins-Bold.ttf"), // Make sure to provide the correct path to your font file.
    });
    Font.register({
      family: "Poppins-regular",
      src: require("../fonts/Poppins/Poppins-Regular.ttf"),
    });

    const styles = {
      headings: {
        marginBottom: "5px",
        fontFamily: "Poppins-semi",
      },
      descriptions: {
        fontSize: "11px",
        fontFamily: "Poppins-regular",
      },
      header: {
        fontSize: "18px",
        textAlign: "center",
        marginBottom: "10px",
        fontFamily: "Poppins-bold",
      },
      container: {
        padding: 20,
        display: "none",
        height: "100%",
        borderTop: "30px",
        borderColor: "#a68f0d",
        color: colorValue,
      },
      subHeader: {
        fontSize: "14px",
        fontWeight: "600",
        textAlign: "center",
        marginBottom: "5px",
        fontFamily: "Poppins-semi",
      },
      info: {
        fontSize: "11px",
        textAlign: "center",
        marginBottom: " 5px",
        fontFamily: "Poppins-regular",
      },
      sectionHeader: {
        fontSize: "14px",
        textAlign: "center",
        fontWeight: "600",
        marginTop: "10px",
        width: "100%",
      },

      linkItem: {
        fontSize: "11px",
        textDecoration: "underline",
        textAlign: "center",
        marginLeft: "4px",
        color: "black",
        marginBottom: "5px",
      },
      jobTitle: {
        textDecoration: "underline",
        marginBottom: "5px",
        fontSize: "11px",
        width: "100%",
        marginTop: "2px",
        fontFamily: "Poppins-semi",
      },
      jobDetails: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "5px",
        fontSize: "11px",
        width: "100%",
      },
      expertiseArea: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
      educationDetails: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "5px",
        fontSize: "11px",
      },
      listItem: {
        fontSize: 11,
        marginLeft: 8,
      },
      school: {
        fontSize: "11px",
        width: "100%",
        textAlign: "left",
        fontFamily: "Poppins-semi",
      },
      degree: {
        fontFamily: "Poppins-semi",
        fontSize: "11px",
      },
      titleContainer: {
        display: "flex",
        flexDirection: "row",
        gap: 20,
        alignItems: "center",
        justifyContent: "space-between",
      },
      lines: {
        borderTop: "2px",
        borderColor: "#a68f0d",
        height: "2px",
        width: "33%",
      },
    };
    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            <View style={styles.header}>
              <Text>
                {personalInfoValues.firstName} {personalInfoValues.lastName}
              </Text>
            </View>
            <View style={styles.subHeader}>
              <Text>{personalInfoValues.jobTitle}</Text>
            </View>
            <View style={styles.info}>
              <Text>
                {personalInfoValues.city} {personalInfoValues.country}{" "}
                {personalInfoValues.address} {personalInfoValues.postalcode}{" "}
                {personalInfoValues.nationality}
              </Text>
              <Text>
                {personalInfoValues.email} {personalInfoValues.phoneNumber}
              </Text>
              <Text>
                {personalInfoValues.dateofbirth}{" "}
                {personalInfoValues.placeofbirth}{" "}
                {personalInfoValues.drivinglicense}
              </Text>
            </View>
            {personalInfoValues.clearance ? (
              <View style={styles.sectionHeader}>
                <View style={styles.titleContainer}>
                  <Text style={styles.lines}></Text>
                  <Text style={styles.headings}>Security Clearance</Text>
                  <Text style={styles.lines}></Text>
                </View>
                <Text style={styles.descriptions}>
                  {personalInfoValues.clearance}
                </Text>
              </View>
            ) : null}
            {editorValue ? (
              <View style={styles.sectionHeader}>
                <View style={styles.titleContainer}>
                  <Text style={styles.lines}></Text>
                  <Text style={styles.headings}>Solutions Architect</Text>
                  <Text style={styles.lines}></Text>
                </View>
                <Text style={styles.descriptions}>{editorValue}</Text>
              </View>
            ) : null}
            {skillsData[0] ? (
              <View style={styles.sectionHeader}>
                <View style={styles.titleContainer}>
                  <Text style={styles.lines}></Text>
                  <Text style={styles.headings}>Technical Expertise</Text>
                  <Text style={styles.lines}></Text>
                </View>

                <View style={styles.expertiseArea}>
                  {skillsData.map((item, index) => (
                    <View key={index} style={styles.expertiseArea}>
                      <Text key={index} style={styles.listItem}>
                        {item}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            ) : null}
            {websiteData[0] ? (
              <View style={styles.sectionHeader}>
                <View style={styles.titleContainer}>
                  <Text style={styles.lines}></Text>
                  <Text style={styles.headings}>Websites and Social Links</Text>
                  <Text style={styles.lines}></Text>
                </View>
                {websiteData.map((item, index) => (
                  <Link key={index} style={styles.linkItem} src={item.link}>
                    {item.label}
                  </Link>
                ))}
              </View>
            ) : null}
            {employementData[0] ? (
              <View style={styles.sectionHeader}>
                <View style={styles.titleContainer}>
                  <Text style={styles.lines}></Text>
                  <Text style={styles.headings}>Professional Experience</Text>
                  <Text style={styles.lines}></Text>
                </View>

                {employementData.map((item, index) => (
                  <View key={index}>
                    <Text style={styles.jobTitle}>{item.jobtitle1}</Text>
                    <View style={styles.jobDetails}>
                      <Text>
                        {item.employer}, {item.jobcity}
                      </Text>
                      <Text>
                        {item.jobstart} - {item.jobend}
                      </Text>
                    </View>
                    <View style={styles.jobDetails}>
                      <Text>{item.jobdescription}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ) : null}
            {educationData[0] ? (
              <View style={styles.sectionHeader}>
                <View style={styles.titleContainer}>
                  <Text style={styles.lines}></Text>
                  <Text style={styles.headings}>
                    Educations & Certifications
                  </Text>
                  <Text style={styles.lines}></Text>
                </View>

                {educationData.map((item, index) => (
                  <View key={index}>
                    <Text style={styles.school}>
                      {item.school} {item.city1}
                    </Text>
                    <View style={styles.educationDetails}>
                      <Text style={styles.degree}>{item.degree}</Text>
                      <Text>
                        {item.start} - {item.end}
                      </Text>
                    </View>
                    <View style={styles.educationDetails}>
                      <Text>{item.edudescription}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ) : null}
            {customSectionData[0] ? (
              <View style={styles.sectionHeader}>
                {customSectionData.map((item, index) => (
                  <View key={index}>
                    <View style={styles.titleContainer}>
                      <Text style={styles.lines}></Text>
                      <Text style={styles.headings}>{item.sectionTitle}</Text>
                      <Text style={styles.lines}></Text>
                    </View>

                    <View style={styles.educationDetails}>
                      <Text style={styles.degree}>
                        {item.activity}, {item.customSectionCity}
                      </Text>
                      <Text>
                        {item.customStartDate} - {item.customEndDate}
                      </Text>
                    </View>
                    <View style={styles.educationDetails}>
                      <Text>{item.customDescription}</Text>
                    </View>
                  </View>
                ))}
              </View>
            ) : null}
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center justify-between ">
      <div
        ref={containerRef}
        className="flex flex-col overflow-y-scroll hide-scrollbar max-[1100px]:mt-0 w-full max-[650px]:w-full h-full p-[20px]"
      >
        <div className="border-t-30 border-resume flex flex-col items-center pt-4 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-t-[15px]">
          <h1 className="text-xl font-bold max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:mb-1">
            {personalInfoValues.firstName} {personalInfoValues.lastName}
          </h1>
          <h2 className="font-semibold text-[14px] max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            {personalInfoValues.jobTitle}
          </h2>
          <p className="text-[11px] max-[650px]:text-[9px]">
            {personalInfoValues.city} {personalInfoValues.country}{" "}
            {personalInfoValues.address} {personalInfoValues.postalcode}{" "}
            {personalInfoValues.nationality}
          </p>
          <p className="text-[11px] max-[650px]:text-[9px]">
            {personalInfoValues.email} {personalInfoValues.phoneNumber}
          </p>
          <p className="text-[11px] max-[650px]:text-[9px]">
            {personalInfoValues.dateofbirth} {personalInfoValues.placeofbirth}{" "}
            {personalInfoValues.drivinglicense}{" "}
          </p>
        </div>
        {personalInfoValues.clearance ? (
          <div className="flex text-[11px] max-[650px]:text-[9px] flex-col my-2 items-center">
            <div className="flex justify-between items-center w-full">
              <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
              <div>
                <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Security Clearance
                </h2>
              </div>
              <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
            </div>

            <p>{personalInfoValues.clearance}</p>
          </div>
        ) : null}
        {editorValue ? (
          <div className="my-2 text-[11px]">
            <div className="flex justify-between items-center w-full">
              <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
              <div>
                <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Solutions Architect
                </h2>
              </div>
              <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
            </div>

            <p className="text-center max-[650px]:text-[9px]">{editorValue}</p>
          </div>
        ) : null}
        {skillsData[0] ? (
          <div className="my-2 text-[11px]">
            <div className="flex justify-between items-center w-full">
              <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
              <div>
                <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Technical Expertise
                </h2>
              </div>
              <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
            </div>

            <div className="flex gap-x-5 flex-wrap px-5 justify-center gap-0">
              {skillsData.map((item, index) => {
                return (
                  <ul
                    key={index}
                    className="text-[11px] font-semibold list-disc max-[650px]:text-[9px] max-[650px]:leading-[1.2em]"
                  >
                    <li className="ml-1">{item}</li>
                  </ul>
                );
              })}
            </div>
          </div>
        ) : null}
        {websiteData[0] ? (
          <div className="my-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            <div className="flex justify-between items-center w-full">
              <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
              <div>
                <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Websites and Social Links
                </h2>
              </div>
              <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
            </div>

            <div className="flex gap-x-5 flex-wrap px-5 justify-center gap-0">
              {websiteData.map((item, index) => {
                return (
                  <h3
                    key={index}
                    className="underline pb-1 max-[650px]:text-[9px] text-[11px]"
                    onClick={() => window.open(`//${item.link}`, "_blank")}
                  >
                    {item.label}
                  </h3>
                );
              })}
            </div>
          </div>
        ) : null}
        {employementData[0] ? (
          <div className="my-2">
            <div className="flex justify-between items-center w-full">
              <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
              <div>
                <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Professional Experience
                </h2>
              </div>
              <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
            </div>

            {employementData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex text-[11px] max-[650px]:text-[9px] flex-col my-4 items-center w-full  max-[650px]:my-2"
                >
                  <h2 className="text-[11px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                    <u>{item.jobtitle1}</u>
                  </h2>
                  <div className="w-full flex justify-between">
                    <p>
                      {item.employer}, {item.jobcity}
                    </p>
                    <p>
                      {item.jobstart} - {item.jobend}
                    </p>
                  </div>
                  <p>{item.jobdescription}</p>
                </div>
              );
            })}
          </div>
        ) : null}
        {educationData[0] ? (
          <div className="my-2">
            <div className="flex justify-between items-center w-full">
              <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
              <div>
                <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Educations & Certifications
                </h2>
              </div>
              <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
            </div>

            {educationData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex max-[650px]:text-[9px] text-[11px] flex-col w-full my-4"
                >
                  <h3 className="font-semibold">
                    {item.school} {item.city1}
                  </h3>
                  <div className="w-full flex justify-between">
                    <p className="font-semibold">{item.degree}</p>
                    <p>
                      {item.start} - {item.end}
                    </p>
                  </div>
                  <p>{item.edudescription}</p>
                </div>
              );
            })}
          </div>
        ) : null}

        {customSectionData[0] ? (
          <div className="my-2">
            {customSectionData.map((item, index) => {
              return (
                <>
                  <div className="flex justify-between items-center w-full">
                    <div className="border-t-2 border-resume h-[2px] w-[30%]"></div>
                    <div>
                      <h2 className="text-[14px] font-semibold text-center max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                        {item.sectionTitle}
                      </h2>
                    </div>
                    <div className="border-t-2 border-resume h-[2px] w-[30%]  "></div>
                  </div>
                  <div
                    key={index}
                    className="flex max-[650px]:text-[9px] text-[11px] flex-col w-full my-4"
                  >
                    <div className="w-full flex justify-between">
                      <h3 className="font-semibold">
                        {item.activity} {item.customSectionCity}
                      </h3>
                      <p>
                        {item.customStartDate} - {item.customEndDate}
                      </p>
                    </div>
                    <p>{item.customDescription}</p>
                  </div>
                </>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        <PDFDownloadLink
          document={<Resume10Download />}
          style={{
            textDecoration: "none",
            padding: "5px 8px",
            color: "black",
            fontWeight: 500,
            backgroundColor: "white",
            border: "1px solid #4A90E2",
            borderRadius: "4px",
            margin: "10px 0",
          }}
        >
          Download PDF
        </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume10Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Resume10;
