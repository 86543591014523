import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import Img from "../../assets/images/img.svg";
import Img1 from "../../assets/images/img1.svg";
import Img2 from "../../assets/images/img6.svg";
import Img3 from "../../assets/images/img3.svg";
import Img4 from "../../assets/images/img4.svg";
import Img5 from "../../assets/images/img5.svg";
import Img6 from "../../assets/images/resume2.png";
import Img7 from "../../assets/images/resume1.png";
import Img8 from "../../assets/images/resume3.png";
import star from "../../assets/images/icons8-star-100.png";
import ResumeTemplates from "../../assets/images/resume-templates.avif";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Resume1 from "../../resume/resume1";
const Home = () => {
  const navigateToDashboard = () => {
    window.location.href = "/dashboard";
  };
  const [tabs, setTabs] = useState(1);
  const handleTabs = (index) => {
    setTabs(index);
  };
  const [tabs1, setTabs1] = useState(1);
  const handleTabs1 = (index) => {
    setTabs1(index);
  };
  const [accordion, setAccordion] = useState(0);
  const handleAccordion = (index) => {
    setAccordion(index === accordion ? null : index);
  };
  const [hover, setHover] = useState(false);
  function showhover() {
    setHover((prev) => !prev);
  }
  const [hover1, setHover1] = useState(false);
  function showhover1() {
    setHover1((prev) => !prev);
  }
  const divAnim = {
    hidden: {
      y: "50px",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        mass: 0.7,
        damping: 0.6,
        duration: 0.4,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
      },
    },
  };

  const onMouseDiv = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div className="w-full  ">
      <div className="w-full flex gap-[20px] flex-col items-center">
        <div className="w-full flex flex-col items-center bg-[#eff2f9]">
          <div className="w-full bg-[#eff2f9] p-[30px] lg:w-[50%] flex flex-col gap-[7px] items-center">
            <p className="font-medium text-[#1a3077]">ONLINE RESUME BUILDER</p>
            <h2 className="font-bold text-[30px] sm:text-[42px] text-center leading-[1.3em] text-[#1e2532]">
              Only 2% of resumes make it past the first round. Be in the top 2%
            </h2>
            <h3 className="text-center text-[20px] font-semibold">
              Use professional field-tested resume templates that follow the
              exact resume rules employers look for. Easy to use and done within
              minutes - try now for free!
            </h3>
            <Link to="/dashboard">
              <button
                onClick={(e) => {
                  window.location.herf = "/dashboard";
                }}
                className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
                Create My Resume
              </button>
            </Link>
            <img
              src={ResumeTemplates}
              alt="resume-builder"
              className="rounded-md"
            />
          </div>
        </div>
        <motion.div
          variants={divAnim}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="w-full bg-white lg:w-[80%] my-[50px] flex flex-col items-center">
          <h2 className="font-bold text-[30px] xl:w-[70%] sm:text-[48px] text-center leading-[1.3em] text-[#1e2532]">
            Reviewed by the community. Trusted by professionals
          </h2>
          <div className="flex w-full flex-col sm:flex-row my-5 gap-[20px] ">
            <div className="w-[100%] hidden xl:block">
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}>
                <SwiperSlide>
                  <div className="w-full  p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Executive excellence! The sophisticated resume secured my
                      leadership role.”
                    </p>
                    <h3 className="font-semibold">– Krishna, India</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full  p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Impressive results! The resume builder elevated my
                      profile, landing me my dream job quickly.”
                    </p>
                    <h3 className="font-semibold">– Ravi, India</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Tech-friendly and effective! My IT resume got a boost,
                      propelling my career forward.”
                    </p>
                    <h3 className="font-semibold ">– Daniel, USA</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full  p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Tech-friendly and effective! My IT resume got a boost,
                      propelling my career forward.”
                    </p>
                    <h3 className="font-semibold">– Daniel, USA</h3>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="w-full sm:w-[65%] block xl:hidden">
              <Swiper
                spaceBetween={15}
                slidesPerView={2}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}>
                <SwiperSlide>
                  <div className="w-full  p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Executive excellence! The sophisticated resume secured my
                      leadership role.”
                    </p>
                    <h3 className="font-semibold">– Krishna, India</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full  p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Impressive results! The resume builder elevated my
                      profile, landing me my dream job quickly.”
                    </p>
                    <h3 className="font-semibold">– Ravi, India</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Tech-friendly and effective! My IT resume got a boost,
                      propelling my career forward.”
                    </p>
                    <h3 className="font-semibold ">– Daniel, USA</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-full  p-[15px] rounded-md flex flex-col gap-[7px]">
                    <div className="flex gap-[5px]">
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                      <img
                        src={star}
                        alt="resume-builder "
                        className="w-[25px]"
                      />
                    </div>
                    <p className="font-medium">
                      “Tech-friendly and effective! My IT resume got a boost,
                      propelling my career forward.”
                    </p>
                    <h3 className="font-semibold">– Daniel, USA</h3>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={divAnim}
          initial="hidden"
          exit="exit"
          whileInView="visible"
          className="w-full bg-white lg:w-[80%] px-[30px] my-[30px] flex flex-col items-center gap-[15px]">
          <h2 className="font-bold lg:w-[60%] text-[30px] sm:text-[42px] text-center leading-[1.3em] text-[#1e2532] mb-2">
            Features designed to help you win your dream job
          </h2>
          <div className="w-full flex justify-center gap-[25px] flex-wrap">
            <div className="w-full sm:w-[48%] lg:w-[31.5%] p-[15px] bg-white rounded-md flex gap-[5px]">
              <div className="w-[15%]">
                <img src={Img} alt="resume" />
              </div>
              <div className="w-[80%] flex flex-col gap-[12px]">
                <h3 className="text-[#282b8f] text-[20px] font-semibold">
                  Easy online resume builder
                </h3>
                <p className="font-medium">
                  Create an awesome resume in minutes, without leaving your web
                  browser.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-[48%] lg:w-[31.5%] p-[15px] bg-white rounded-md flex gap-[5px]">
              <div className="w-[15%]">
                <img src={Img1} alt="resume" />
              </div>
              <div className="w-[80%] flex flex-col gap-[12px]">
                <h3 className="text-[#282b8f] text-[20px] font-semibold">
                  Automatic spell-checker
                </h3>
                <p className="font-medium">
                  Our built-in spell-checker takes care of the grammar for you.
                  Create a resume with zero typos or errors.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-[48%] lg:w-[31.5%] p-[15px] bg-white rounded-md flex gap-[5px]">
              <div className="w-[15%]">
                <img src={Img2} alt="resume" />
              </div>
              <div className="w-[80%] flex flex-col gap-[12px]">
                <h3 className="text-[#282b8f] text-[20px] font-semibold">
                  Your data is safe
                </h3>
                <p className="font-medium">
                  Your data is kept private and protected by strong 256-bit
                  encryption.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-[48%] lg:w-[31.5%] p-[15px] bg-white rounded-md flex gap-[5px]">
              <div className="w-[15%]">
                <img src={Img3} alt="resume" />
              </div>
              <div className="w-[80%] flex flex-col gap-[12px]">
                <h3 className="text-[#282b8f] text-[20px] font-semibold">
                  Automatic summary generator
                </h3>
                <p className="font-medium">
                  Create a powerful resume profile or cover letter in one click.
                  Writer’s block is no longer an obstacle. Try for free!
                </p>
              </div>
            </div>
            <div className="w-full sm:w-[48%] lg:w-[31.5%] p-[15px] bg-white rounded-md flex gap-[5px]">
              <div className="w-[15%]">
                <img src={Img4} alt="resume" />
              </div>
              <div className="w-[80%] flex flex-col gap-[12px]">
                <h3 className="text-[#282b8f] text-[20px] font-semibold">
                  Approved templates
                </h3>
                <p className="font-medium">
                  Professionally-designed resume templates and examples. Just
                  edit and download in 5 minutes.
                </p>
              </div>
            </div>
            <div className="w-full sm:w-[48%] lg:w-[31.5%] p-[15px] bg-white rounded-md flex gap-[5px]">
              <div className="w-[15%]">
                <img src={Img5} alt="resume" />
              </div>
              <div className="w-[80%] flex flex-col gap-[12px]">
                <h3 className="text-[#282b8f] text-[20px] font-semibold">
                  AI pre-written phrases
                </h3>
                <p className="font-medium">
                  Use the power of AI and data analysis, choose pre-generated
                  effective phrases and keywords.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={divAnim}
          exit="exit"
          initial="hidden"
          whileInView="visible"
          className="w-full  lg:px-[100px] px-[30px] py-[50px] flex flex-col bg-[#eff2f9] gap-[20px]">
          <div className="w-full justify-center items-center flex flex-col sm:flex-row gap-[30px] lg:gap-[80px]">
            <div className="w-full sm:w-[45%] text-center">
              <img
                src={Img6}
                alt="resume-builder"
                className="rounded-md w-[200px]"
              />
            </div>
            <div className="w-full sm:w-[45%] flex flex-col items-start gap-[12px]">
              <h2 className="font-bold  text-[30px] sm:text-[42px] leading-[1.3em] text-[#1e2532]">
                Use the best resume maker as your guide
              </h2>
              <p className="font-medium">
                Getting that dream job can seem like an impossible task. We’re
                here to change that. Give yourself a real advantage with the
                best online resume maker: created by experts, improved by data,
                trusted by millions of professionals.
              </p>
              <button
                onClick={navigateToDashboard}
                className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
                Create My Resume
              </button>
            </div>
          </div>
          <div className="w-full my-4 flex flex-wrap justify-center gap-[30px]">
            <div className="w-full sm:w-[47%] lg:w-[30%] p-[25px] flex flex-col gap-[7px]">
              <img
                src="https://resume.io/assets/landing/home/resume-maker/feature-1-edf4481d69166ac81917d1e40e6597c8d61aa970ad44367ce78049bf830fbda5.svg"
                alt="resume-builder"
                className="w-[70px] h-[70px]"
              />
              <h3 className="font-semibold text-[22px] text-[#1a1c6a]">
                Make a resume that wins interviews
              </h3>
              <p className="font-medium">
                Use our resume maker with its advanced creation tools to tell a
                professional story that engages recruiters, hiring managers and
                even CEOs.
              </p>
            </div>
            <div className="w-full sm:w-[47%] lg:w-[30%] p-[25px] flex flex-col gap-[7px]">
              <img
                src="https://resume.io/assets/landing/home/resume-maker/feature-2-a7a471bd973c02a55d1b3f8aff578cd3c9a4c5ac4fc74423d94ecc04aef3492b.svg"
                className="w-[70px] h-[70px]"
              />
              <h3 className="font-semibold text-[22px] text-[#1a1c6a]">
                Resume writing made easy
              </h3>
              <p className="font-medium">
                Resume writing has never been this effortless. Pre-generated
                text, visual designs and more - all already integrated into the
                resume maker. Just fill in your details.
              </p>
            </div>
            <div className="w-full sm:w-[47%] lg:w-[30%] p-[25px] flex flex-col gap-[7px]">
              <img
                src="https://resume.io/assets/landing/home/resume-maker/feature-3-4e87a82f83e260488c36f8105e26f439fdc3ee5009372bb5e12d9421f32eabdd.svg"
                alt="resume-builder"
                className="w-[70px] h-[70px]"
              />
              <h3 className="font-semibold text-[22px] text-[#1a1c6a]">
                A recruiter-tested CV maker tool
              </h3>
              <p className="font-medium">
                Our Resume Builder and its pre-generated content are tested by
                recruiters and IT experts. We help your resume become truly
                competitive in the hiring process.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
      <motion.div
        variants={divAnim}
        exit="exit"
        initial="hidden"
        whileInView="visible"
        className="w-full px-[30px] lg:px-[120px] py-[80px] bg-[#282b8f] text-white flex flex-col lg:flex-row gap-[40px]">
        <div className="w-full lg:w-[40%] flex flex-col items-start gap-[10px]">
          <h2 className="font-bold text-[40px] leading-[1.3em]">
            Beautiful ready-to-use resume templates
          </h2>
          <p className="font-medium">
            Win over employers and recruiters by using one of our 25+ elegant,
            professionally-designed resume templates. Download to word or PDF.
          </p>
          <button
            onClick={navigateToDashboard}
            className="px-[30px] py-[10px] bg-[#1170cd] rounded-md my-2 text-white font-semibold">
            Explore Template
          </button>
        </div>
        <div className="w-full lg:w-[60%]">
          <div className="w-full hidden sm:block">
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg1 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg2 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg3 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg4 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg5 bg-center bg-cover bg-no-repeat"></div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="w-full block sm:hidden">
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg1 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg2 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg3 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg4 bg-cover bg-no-repeat"></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full h-[300px] bg-resumeImg5 bg-center bg-cover bg-no-repeat"></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </motion.div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        exit="exit"
        whileInView="visible"
        className="w-full px-[30px] lg:px-[120px] py-[80px] bg-[#eff2f9] flex flex-col sm:flex-row justify-center items-center gap-[50px]">
        <div className="w-full sm:w-[40%] ">
          <img
            src={Img6}
            alt="resume-builder"
            className="w-[300px] sm:w-[40%] text-centre"
          />
        </div>
        <div className="w-full sm:w-[50%] flex flex-col gap-[12px]">
          <h2 className="font-bold text-[30px] lg:text-[40px]  leading-[1.3em]">
            Try our professional Resume Builder Now!
          </h2>
          <p className="font-medium">
            Save time with our easy 3-step resume builder. No more writer’s
            block or formatting difficulties in Word. Rapidly make a perfect
            resume employers love.
          </p>
          <div className="w-full flex flex-col lg:flex-row gap-[0px] lg:gap-[30px]">
            <button
              onClick={navigateToDashboard}
              className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
              Create My Resume
            </button>
          </div>
        </div>
      </motion.div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        exit="exit"
        whileInView="visible"
        className="w-full px-[30px] lg:px-[120px] py-[30px] bg-white flex flex-col sm:flex-row justify-center items-center gap-[50px]">
        <div className="w-full sm:w-[50%] flex flex-col gap-[12px]">
          <p className="font-medium text-[#1a3077]">SECURE YOUR DREAM JOB</p>
          <h2 className="font-bold text-[30px] lg:text-[40px]  leading-[1.3em]">
            Self-Review Capabilities
          </h2>
          <p className="font-medium">
            Take control of your narrative by utilizing our self-review feature.
            Conduct a comprehensive evaluation of your resume, ensuring
            accuracy, coherence, and alignment with industry standards. This
            empowering tool enables you to fine-tune every detail, presenting
            your qualifications with precision.
          </p>
          <div className="w-full flex flex-col lg:flex-row gap-[0px] lg:gap-[30px]">
            <button
              onClick={navigateToDashboard}
              className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
              Create My Resume
            </button>
            <button
              onClick={navigateToDashboard}
              className="px-[30px] py-[10px] font-semibold  rounded-md my-3 border bg-transparent text-[#1170cd] border-[#1170cd] hover:bg-[#1170cd] hover:text-white ">
              Explore Now
            </button>
          </div>
        </div>
        <div className="w-full sm:w-[40%]">
          <img
            src={Img7}
            alt="resume-builder"
            className="rounded-md w-[300px]"
          />
        </div>
      </motion.div>
      <div>
        <div className="w-full px-[30px] sm:px-[50px] my-5 flex flex-col sm:flex-row gap-[30px] items-center justify-center">
          <div className="w-full sm:w-[50%] lg:w-[30%] rounded-md relative flex flex-col z-[5]  justify-end h-[420px] lg:h-[400px]">
            <img
              onMouseEnter={showhover}
              onMouseLeave={showhover}
              src="https://s3.resume.io/cdn-cgi/image/width=448,height=392,dpr=1,fit=cover,format=auto/uploads/blog_post/featured_image/298/how-to-write-a-resume.jpg"
              alt="locove"
              className="rounded-md absolute w-full top-0 left-0 h-full z-[2] "
            />
            <div
              onMouseEnter={showhover}
              onMouseLeave={showhover}
              className="w-full flex-col  relative z-[7] opacity-[0.9] bg-[#493995] p-[25px]">
              <h2 className="text-[22px] text-white opacity-1 font-bold leading-[1.3em]">
                The Universal guide on how to write the perfect resume
              </h2>
              <p className="opacity-1 mt-2 text-white">RESUME GUIDE</p>
            </div>

            {hover ? (
              <motion.div
                variants={onMouseDiv}
                initial="hidden"
                exit="exit"
                whileInView="visible"
                onMouseEnter={showhover}
                onMouseLeave={showhover}
                className="absolute w-full z-[10] h-full flex flex-col p-[30px] bg-[#493995] rounded-md top-0 left-0">
                <div className="w-full flex flex-col items-start gap-[7px]">
                  <h2 className="text-[26px] text-white opacity-1 font-bold leading-[1.3em]">
                    The Universal guide on how to write the perfect resume
                  </h2>
                  <p className="opacity-1 mt-2 text-white">RESUME GUIDE</p>
                  <p className="text-white font-medium">
                    Arm yourself with the golden rules of resume writing: what
                    to include in your resume, which formatting standards to
                    follow, which resume sections are necessary and which are
                    optional, how to present your work experience and much more.
                  </p>
                  <button className="py-[12px] text-white font-semibold">
                    Read More
                  </button>
                </div>
              </motion.div>
            ) : null}
          </div>
          <div className="w-full sm:w-[50%] lg:w-[30%] rounded-md relative flex flex-col z-[5]  justify-end h-[420px] lg:h-[400px]">
            <img
              onMouseEnter={showhover1}
              onMouseLeave={showhover1}
              src="https://s3.resume.io/cdn-cgi/image/width=448,height=392,dpr=1,fit=cover,format=auto/uploads/blog_post/featured_image/314/How-to-Write-a-Cover-Letter-Expert-Guide.PNG"
              alt="locove"
              className="rounded-md absolute w-full top-0 left-0 h-full z-[2] "
            />
            <div
              onMouseEnter={showhover1}
              onMouseLeave={showhover1}
              className="w-full flex-col  relative z-[7] opacity-[0.9] bg-[#338699] p-[25px]">
              <h2 className="text-[22px] text-white opacity-1 font-bold leading-[1.3em]">
                A Comprehensive Guide on How to Write a Cover Letter
              </h2>
              <p className="opacity-1 mt-2 text-white">COVER LETTER GUIDE</p>
            </div>

            {hover1 ? (
              <motion.div
                variants={onMouseDiv}
                initial="hidden"
                whileInView="visible"
                exit="exit"
                onMouseEnter={showhover1}
                onMouseLeave={showhover1}
                className="absolute w-full z-[10] h-full flex flex-col p-[30px] bg-[#338699] rounded-md top-0 left-0">
                <div className="w-full flex flex-col items-start gap-[7px]">
                  <h2 className="text-[26px] text-white opacity-1 font-bold leading-[1.3em]">
                    A Comprehensive guide on how to write a cover letter
                  </h2>
                  <p className="opacity-1 mt-2 text-white">
                    COVER LETTER GUIDE
                  </p>
                  <p className="text-white font-medium">
                    Our guide on cover letter writing answers all of the most
                    important questions: why do you need a cover letter? What is
                    the purpose of a cover letter? How to use creative writing
                    when describing your job history and professional image?
                  </p>
                  <button className="py-[12px] text-white font-semibold">
                    Read More
                  </button>
                </div>
              </motion.div>
            ) : null}
          </div>
        </div>
      </div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        whileInView="visible"
        exit="exit"
        className="p-[30px] w-full h-[600px] bg-[#eff2f9] flex flex-col gap-[15px] items-center justify-center">
        <h2 className="font-bold text-[30px] lg:text-[40px] w-full lg:w-[40%] text-center  leading-[1.3em]">
          User-friendly. Professional. Effective. Try our Resume builder today!
        </h2>
        <p className="w-full lg:w-[45%] text-center font-medium">
          Experience a seamless and user-friendly resume creation process that
          empowers you to effortlessly articulate your professional journey. Our
          platform is engineered for simplicity, allowing you to focus on
          showcasing your skills and achievements without the unnecessary
          complexities.
        </p>
        <div className="w-full flex justify-center items-center flex-col lg:flex-row gap-[0px] lg:gap-[20px]">
          <button
            onClick={navigateToDashboard}
            className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
            Create My Resume
          </button>
          <button
            onClick={navigateToDashboard}
            className="px-[30px] py-[10px] font-semibold rounded-md my-3 border bg-transparent text-[#1170cd] border-[#1170cd] hover:bg-[#1170cd] hover:text-white ">
            Explore Now
          </button>
        </div>
      </motion.div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        exit="exit"
        whileInView="visible"
        className="w-full px-[30px] sm:px-[50px] bg-white py-[80px] flex flex-col items-center">
        <h2 className="font-bold text-[30px] lg:text-[40px] w-full lg:w-[50%] text-center  leading-[1.3em]">
          Effortlessly make a job-worthy resume and cover letter that gets you
          hired faster
        </h2>
        <div className="w-full flex flex-col lg:flex-row bg-white justify-center my-5 px-[0px] sm:px-[80px] gap-[50px]">
          <div className="w-full lg:w-[45%] bg-[#eff2f9] p-[25px] rounded-md flex items-center">
            {tabs === 1 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">
                  Live Preview Customization
                </h2>
                <p className="font-medium">
                  Offers users an interactive live preview of their resume
                  during the creation or editing process, allowing instant
                  visualization of changes and ensuring the document meets their
                  expectations.
                </p>
              </div>
            ) : null}
            {tabs === 2 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">
                  Add AI Pre-Written Phrases
                </h2>
                <p className="font-medium">
                  Harness the power of cutting-edge artificial intelligence (AI)
                  to enhance the effectiveness of your resume. Our platform
                  employs advanced algorithms to analyze and optimize content,
                  ensuring that your resume not only meets but exceeds industry
                  standards. Leverage AI-driven insights to refine your document
                  and elevate your professional profile
                </p>
              </div>
            ) : null}
            {tabs === 4 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">
                  Selection of Resume Templates
                </h2>
                <p className="font-medium">
                  Choose from an extensive array of meticulously crafted
                  templates, each designed to cater to diverse professional
                  styles and industry preferences. Our collection ensures that
                  your resume not only stands out but also reflects your unique
                  personality and career trajectory.
                </p>
              </div>
            ) : null}
            {tabs === 5 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">
                  Document Formatting
                </h2>
                <p className="font-medium">
                  Supports versatile document formats like PDF or Word, enabling
                  users to download and share their resumes in a preferred
                  format, enhancing compatibility for various applications.
                </p>
              </div>
            ) : null}
          </div>
          <div className="w-full lg:w-[45%] flex flex-col gap-[15px]">
            <button
              onClick={() => handleTabs(1)}
              className="border-l-2 border-black font-semibold p-[7px]">
              LIVE PREVIEW CUSTOMIZATION
            </button>
            <button
              onClick={() => handleTabs(2)}
              className="border-l-2 border-black font-semibold p-[7px]">
              ADD AI PRE-WRITTEN PHRASES
            </button>

            <button
              onClick={() => handleTabs(4)}
              className="border-l-2 border-black font-semibold p-[7px]">
              SELECTION OF RESUME TEMPLATES
            </button>
          </div>
        </div>
      </motion.div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        exit="exit"
        whileInView="visible"
        className="w-full flex bg-[#eff2f9] flex-col gap-[15px] items-center p-[30px] sm:p-[80px]">
        <h2 className="font-bold text-[30px] lg:text-[40px] w-full lg:w-[40%] text-center  leading-[1.3em]">
          Create perfect resumes for the modern job market
        </h2>
        <p className="w-full lg:w-[45%] text-center font-medium">
          Creating a resume has never been this easy! In three simple steps,
          create the perfect document to impress hiring managers and employers.
          Minimum time, maximum professional quality.
        </p>
        <button
          onClick={navigateToDashboard}
          className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
          Create My Resume
        </button>
        <div className="w-full lg:w-[50%] flex flex-col gap-[20px]">
          <div className="w-full flex flex-col sm:flex-row gap-[15px] items-center justify-center">
            <button
              onClick={() => handleTabs1(2)}
              className="w-full sm:w-[30%] p-[7px] font-semibold bg-white rounded-md">
              Customer Support
            </button>
            <button
              onClick={() => handleTabs1(3)}
              className="w-full sm:w-[30%] p-[7px] font-semibold bg-white rounded-md">
              Self-Review Capabilities
            </button>
          </div>
          <div className="w-full flex flex-col lg:flex-row bg-white justify-center my-5 px-[0px] sm:p-[30px] gap-[50px]">
            {tabs1 === 1 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">Seamless Payment</h2>
                <p className="font-medium">
                  Facilitates a smooth payment experience for users subscribing
                  to premium features or services on the resume website,
                  ensuring secure transactions through integrated payment
                  gateways.
                </p>
              </div>
            ) : null}
            {tabs1 === 2 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">
                  Customer Support Integration
                </h2>
                <p className="font-medium">
                  Integrates a robust customer support system, ensuring users
                  have access to timely assistance, resolving queries, and
                  providing guidance throughout their interaction with the
                  website, enhancing overall user satisfaction.
                </p>
              </div>
            ) : null}
            {tabs1 === 3 ? (
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-[25px]">
                  Self-Review Capabilities
                </h2>
                <p className="font-medium">
                  Take control of your narrative by utilizing our self-review
                  feature. Conduct a comprehensive evaluation of your resume,
                  ensuring accuracy, coherence, and alignment with industry
                  standards. This empowering tool enables you to fine-tune every
                  detail, presenting your qualifications with precision.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        whileInView="visible"
        exit="exit"
        className="w-full px-[30px] items-center lg:px-[180px] py-[100px] bg-[#282b8f] text-white flex flex-col lg:flex-row gap-[40px]">
        <div className="w-full lg:w-[45%] flex flex-col items-start gap-[10px]">
          <p className="text-[#ecba44] font-semibold">
            START BUILDING YOUR CAREER
          </p>
          <h2 className="font-bold text-[40px] leading-[1.3em]">
            Professional resumes for effective job interviews
          </h2>
          <p className="font-medium">
            Ai based resume builder is fast, easy to use and very precise. It
            works on powerful ReactJS and is fully automatic.
          </p>
          <button
            onClick={navigateToDashboard}
            className="px-[30px] py-[10px] bg-[#1170cd] rounded-md my-2 text-white font-semibold">
            Explore Now
          </button>
        </div>
        <div className="w-full flex justify-center lg:w-[55%]">
          <img
            src={Img8}
            alt="resume-builder"
            className="sm:w-[70%] rounded-md"
          />
        </div>
      </motion.div>
      <div className="flex flex-col p-[30px] sm:p-[80px] bg-white  items-center w-full gap-[20px]">
        <h2 className="text-[42px] leading-[1.3em] lg:w-[30%] font-bold text-center">
          Frequently Asked Questions
        </h2>
        <div className="w-full lg:w-[60%] flex flex-col gap-2">
          <div className="w-full transition-all flex flex-col gap-1">
            <div
              onClick={() => handleAccordion(1)}
              className="p-[7px] rounded-md border border-black flex justify-between items-center cursor-pointer">
              <h3 className="font-bold">
                How Can I use Instant AI Resume Builder free?
              </h3>
              {accordion === 1 ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            {accordion === 1 ? (
              <motion.div
                variants={onMouseDiv}
                initial="hidden"
                whileInView="visible"
                exit="exit"
                className="p-[12px]  rounded-md border border-black flex justify-between ">
                <p>
                  Instant AI Resume Builder has a few different tools you can
                  use 100% for free without entering any credit card details.
                </p>
              </motion.div>
            ) : null}
          </div>
          <div className="w-full transition-all flex flex-col gap-1">
            <div
              onClick={() => handleAccordion(2)}
              className="p-[7px] rounded-md border border-black flex justify-between items-center cursor-pointer">
              <h3 className="font-bold">How Can I customize my resume?</h3>
              {accordion === 2 ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            {accordion === 2 ? (
              <motion.div
                variants={onMouseDiv}
                initial="hidden"
                whileInView="visible"
                exit="exit"
                className="p-[12px]  rounded-md border border-black flex justify-between ">
                <p>
                  Our resume templates are designed to adapt to your content and
                  look great across all of our designs
                </p>
              </motion.div>
            ) : null}
          </div>
          <div className="w-full transition-all flex flex-col gap-1">
            <div
              onClick={() => handleAccordion(3)}
              className="p-[7px] rounded-md border border-black flex justify-between items-center cursor-pointer">
              <h3 className="font-bold">Can I download My resume to PDF?</h3>
              {accordion === 3 ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            {accordion === 3 ? (
              <motion.div
                variants={onMouseDiv}
                initial="hidden"
                exit="exit"
                animate="visible"
                className="p-[12px]  rounded-md border border-black flex justify-between ">
                <p>
                  Once your resume is ready there are a number of ways you can
                  export your resumes or cover letters to start applying for
                  jobs. You can download a PDF of your resume either directly
                  from your Dashboard or from the Resume Editor
                </p>
              </motion.div>
            ) : null}
          </div>
          <div className="w-full transition-all flex flex-col gap-1">
            <div
              onClick={() => handleAccordion(4)}
              className="p-[7px] rounded-md border border-black flex justify-between items-center cursor-pointer">
              <h3 className="font-bold">
                How do I cancel, downgrade or delete my account?
              </h3>
              {accordion === 4 ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            {accordion === 4 ? (
              <motion.div
                variants={onMouseDiv}
                initial="hidden"
                whileInView="visible"
                exit="exit"
                className="p-[12px]  rounded-md border border-black flex justify-between ">
                <p>
                  You can cancel a Resume.io subscription right from our website
                  without even logging into the app. To do that visit our
                  Contact Us page here and then click the "Cancel Subscription"
                  option from the menu.
                </p>
              </motion.div>
            ) : null}
          </div>
        </div>
      </div>
      <motion.div
        variants={divAnim}
        initial="hidden"
        exit="exit"
        whileInView="visible"
        className="p-[30px] sm:p-[50px] lg:p-[80px] w-full flex flex-col gap-2 items-center justify-center">
        <h2 className="font-bold text-[36px] lg:text-[48px] text-clearanceDarkBlue leading-[1.3em] text-center w-full sm:w-[60%] lg:w-[40%]">
          Join over 33,613,000 users Worldwide
        </h2>
        <p className="font-medium text-center">
          Start for free - try our resume builder now
        </p>
        <button
          onClick={navigateToDashboard}
          className="px-[30px] py-[10px] font-semibold rounded-md my-3 bg-[#1170cd] hover:bg-[#1b2f43] text-white">
          Create My Resume
        </button>
      </motion.div>
    </div>
  );
};

export default Home;
