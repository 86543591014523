import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import "./style.css";
import { Provider } from "react-redux";
import store from "../store";
// import exportAsImage from "./resumecontrolbar";
import { setResumeImage1 } from "../state/reducers/resumeimageSlice";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";
import ReactToPrint from "react-to-print";
import { Navigate, useNavigate } from "react-router-dom";

const Resume1 = () => {
  const dispatch = useDispatch();
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage1(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const imageValue = useSelector((state) => state.image.imageValue);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const navigate = useNavigate();
  const Resume1Download = () => {
    Font.register({
      family: "Roboto-regular",
      src: require("../fonts/Roboto/Roboto-Regular.ttf"),
    });
    Font.register({
      family: "Roboto-semi",
      src: require("../fonts/Roboto/Roboto-Medium.ttf"),
    });

    Font.register({
      family: "Roboto-bold",
      src: require("../fonts/Roboto/Roboto-Bold.ttf"),
    });

    const styles = {
      container: {
        flexDirection: "column",
        width: "100%",
        padding: "15px",
        color: colorValue,
      },
      header: {
        flexDirection: "row",
        gap: "15px",
        marginBottom: 12,
        alignItems: "top",
        justifyContent: "space-between",
      },
      leftColumn: {
        width: "45%",
        display: "flex",
        flexDirection: "flex-column",
        gap: "15px",
      },
      rightColumn: {
        width: "55%",
        display: "flex",
        flexDirection: "flex-column",
        gap: "15px",
      },

      content: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 20,
      },
      leftside: {
        width: "100%",
      },
      rightside: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      },
      box: {
        padding: "10px",
        backgroundColor: "#eeeeee",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      },
      title: {
        fontSize: "14px",
        marginBottom: 5,
        fontFamily: "Roboto-bold",
      },
      name: {
        fontSize: "30px",
        fontFamily: "Roboto-bold",
        lineHeight: 1,
      },
      heading: {
        fontSize: "20px",
        fontFamily: "Roboto-bold",
      },
      subheading: {
        padding: 5,
        paddingLeft: 8,
        backgroundColor: "#535353",
        color: "white",
        fontSize: "15px",
        fontFamily: "Roboto-semi",
      },
      text: {
        fontSize: "11px",
        marginBottom: 2,
        fontFamily: "Roboto-regular",
      },
      link: {
        fontSize: "11px",
        color: "black",
        marginBottom: 2,
      },
      img: {
        width: "100px",
        height: "100px",
      },
      innerBox: {
        marginBottom: 12,
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      },
      leftBox: {
        padding: "8px",
        backgroundColor: "#eeeeee",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        borderRadius: "12px",
      },
      leftBoxHeading: {
        fontSize: "14px",
        fontFamily: "Roboto-semi",
      },
      jobtitle: {
        fontSize: "20px",
        fontFamily: "Roboto-semi",
      },
      dateText: {
        fontSize: "11px",
        fontFamily: "Roboto-semi",
      },
    };

    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            <View style={styles.header}>
              <View style={styles.leftColumn}>
                {imageValue ? (
                  <Image style={styles.img} src={imageValue} />
                ) : null}
                {personalInfoValues.jobTitle ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.jobtitle}>
                      {personalInfoValues.jobTitle}
                    </Text>
                  </View>
                ) : null}

                {personalInfoValues.email ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.leftBoxHeading}>Email</Text>
                    <Text style={styles.text}>{personalInfoValues.email}</Text>
                  </View>
                ) : null}

                {personalInfoValues.phoneNumber ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.leftBoxHeading}>Phone</Text>
                    <Text style={styles.text}>
                      {personalInfoValues.phoneNumber}
                    </Text>
                  </View>
                ) : null}

                {personalInfoValues.address ||
                personalInfoValues.city ||
                personalInfoValues.postalcode ||
                personalInfoValues.country ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.leftBoxHeading}>Address</Text>
                    <Text style={styles.text}>
                      {personalInfoValues.address} {personalInfoValues.city}{" "}
                      {personalInfoValues.postalcode}{" "}
                      {personalInfoValues.country}
                    </Text>
                  </View>
                ) : null}

                {personalInfoValues.dateofbirth ||
                personalInfoValues.placeofbirth ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.leftBoxHeading}>
                      Date | Birth Place
                    </Text>
                    <Text style={styles.text}>
                      {personalInfoValues.placeofbirth} |{" "}
                      {personalInfoValues.dateofbirth}
                    </Text>
                  </View>
                ) : null}
                {personalInfoValues.drivinglicense ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.leftBoxHeading}>Driving License</Text>
                    <Text style={styles.text}>
                      {personalInfoValues.drivinglicense}
                    </Text>
                  </View>
                ) : null}
                {personalInfoValues.nationality ? (
                  <View style={styles.leftBox}>
                    <Text style={styles.leftBoxHeading}>Nationality</Text>
                    <Text style={styles.text}>
                      {personalInfoValues.nationality}
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={styles.rightColumn}>
                <Text style={styles.name}>{personalInfoValues.firstName}</Text>
                <Text style={styles.name}>{personalInfoValues.lastName}</Text>
                {editorValue ? (
                  <View style={styles.box}>
                    <Text style={styles.heading}>Profile</Text>
                    <Text style={styles.text}>{editorValue}</Text>
                  </View>
                ) : null}

                <View style={styles.rightside}>
                  {websiteData[0] ? (
                    <View style={styles.box}>
                      <Text style={styles.heading}>Links</Text>
                      {websiteData.map((item, index) => (
                        <Link style={styles.link} key={index} src={item.link}>
                          {item.label}
                        </Link>
                      ))}
                    </View>
                  ) : null}
                  {skillsData[0] ? (
                    <View style={styles.box}>
                      <Text style={styles.heading}>Skills</Text>
                      {skillsData.map((item, index) => (
                        <Text style={styles.text} key={index}>
                          {item}
                        </Text>
                      ))}
                    </View>
                  ) : null}
                  {personalInfoValues.clearance ? (
                    <View style={styles.box}>
                      <Text style={styles.heading}>Security Clearance:</Text>
                      <Text style={styles.text}>
                        {personalInfoValues.clearance}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
            <View style={styles.content}>
              <View style={styles.leftside}>
                {employementData[0] ? (
                  <View>
                    <Text style={styles.title}>Employement History</Text>
                    {employementData.map((item, index) => (
                      <View key={index} style={styles.innerBox}>
                        <Text style={styles.subheading}>
                          {item.jobtitle1} | {item.employer} | {item.jobcity}
                        </Text>
                        <Text style={styles.dateText}>
                          {item.jobstart} - {item.jobend}
                        </Text>
                        <Text style={styles.text}>{item.jobdescription}</Text>
                      </View>
                    ))}
                  </View>
                ) : null}

                {educationData[0] ? (
                  <View>
                    <Text style={styles.title}>Education</Text>
                    {educationData.map((item, index) => (
                      <View key={index} style={styles.innerBox}>
                        <Text style={styles.subheading}>
                          {item.degree} | {item.school} | {item.city1}
                        </Text>
                        <Text style={styles.dateText}>
                          {item.start} - {item.end}
                        </Text>
                        <Text style={styles.text}>{item.edudescription}</Text>
                      </View>
                    ))}
                  </View>
                ) : null}
                {customSectionData[0] ? (
                  <View>
                    {customSectionData.map((item, index) => (
                      <View key={index} style={styles.innerBox}>
                        <Text style={styles.title}>{item.sectionTitle}</Text>
                        <Text style={styles.subheading}>
                          {item.activity} | {item.customSectionCity}
                        </Text>
                        <Text style={styles.dateText}>
                          {item.customStartDate} - {item.customEndDate}
                        </Text>
                        <Text style={styles.text}>
                          {item.customDescription}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : null}
              </View>
            </View>
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    /* prettier-ignore */
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center justify-between ">
      <div
        ref={containerRef}
        className="flex flex-col overflow-y-scroll hide-scrollbar max-[1100px]:mt-0 w-full max-[650px]:w-full h-auto p-[15px]"
        id="doc">
        <div className="flex gap-[15px] items-start max-[650px]:items-start">
          <div className="w-[45%] flex flex-col gap-[15px]">
            {imageValue ? <img src={imageValue} alt="resume" className="w-[100px] h-[100px]" />   : null}
        
            {personalInfoValues.jobTitle ? 
            <div className="p-2 rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
             <h3 className="font-semibold text-[20px] max-[650px]:text-[9px] max-[650px]:leading-[1.4em]">
              {personalInfoValues.jobTitle}
            </h3>
            </div>
              : null}
{personalInfoValues.email ? 
            <div className="p-2 flex flex-col rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
            <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
             Email
                    </h2>  
            <p className="text-[11px] max-[650px]:text-[9px] ">
              {personalInfoValues.email}
            </p>
            </div>
              : null}

{personalInfoValues.phoneNumber ? 
            <div className="p-2 flex flex-col rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
            <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
            Phone
                    </h2>  
            <p className="text-[11px] max-[650px]:text-[9px] ">
              {personalInfoValues.phoneNumber}
            </p>
            </div>
              : null}


{personalInfoValues.address || personalInfoValues.city || personalInfoValues.postalcode || personalInfoValues.country ? 
            <div className="p-2 flex flex-col  rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
            <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
          Address
                    </h2>  
            <p className="text-[11px] max-[650px]:text-[9px] ">
              {personalInfoValues.address} {personalInfoValues.city} {personalInfoValues.postalcode} {personalInfoValues.country} 
            </p>
            </div>
              : null}


            {personalInfoValues.dateofbirth || personalInfoValues.placeofbirth ? 
            <div className="p-2 flex flex-col  rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
            <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
      Date | Place of Birth
                    </h2>  
            <p className="text-[11px] max-[650px]:text-[9px] ">
             {personalInfoValues.dateofbirth} | {personalInfoValues.placeofbirth}
            </p>
            </div>
              : null}
            
            {personalInfoValues.drivinglicense? 
            <div className="p-2 flex flex-col  rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
            <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
      Driving License
                    </h2>  
            <p className="text-[11px] max-[650px]:text-[9px] ">
             {personalInfoValues.drivinglicense}
            </p>
            </div>
              : null}
          
          {personalInfoValues.nationality? 
            <div className="p-2 flex flex-col  rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
            <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
     Nationality
                    </h2>  
            <p className="text-[11px] max-[650px]:text-[9px] ">
             {personalInfoValues.nationality}
            </p>
            </div>
              : null}


          </div>
          <div className="w-[55%] flex flex-col gap-[15px]">
            <h1 className="text-[30px] leading-[1em] font-bold ml-10 max-[650px]:text-[12px] max-[650px]:leading-[1.3em]">
              {personalInfoValues.firstName}
            </h1>
            <h2 className="text-[30px] leading-[1em] font-bold ml-10 max-[650px]:text-[12px] max-[650px]:leading-[1.3em]">
              {personalInfoValues.lastName}
            </h2>
            {editorValue ? (
              <div className="px-4 py-4 flex flex-col rounded-xl bg-[#eeeeee] max-[650px]:text-[9px] text-[11px]">
                <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                   Profile
                    </h2>  
                {editorValue}
              </div>
            ) : null}

            <div className="w-full">
              {
                websiteData[0] ? (
                  <div className="bg-[#eeeeee] mb-4 px-4 py-4 rounded-xl max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                    <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      Links
                    </h2>
                    {websiteData.map((item, index) => {
                      return (
                        <h3 key={index}
                          onClick={() =>
                            window.open(`//${item.link}`, "_blank")
                          }
                          className="underline pb-1 text-[11px] max-[650px]:text-[9px]">
                          {item.label}
                        </h3>
                      );
                    })}
                  </div>
                ) : null
              }

              {
                skillsData[0] ? (
                  <div className="bg-[#eeeeee] mb-4 px-4 py-4 rounded-xl max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                    <h2 className="text-[14px] font-semibold mb-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      Skills
                    </h2>
                    {skillsData.map((item, index) => {
                      return (
                        <h3
                          className="text-[11px] max-[650px]:text-[9px]"
                          key={index}>
                          {item}
                        </h3>
                      );
                    })}
                  </div>
                ) : null
              }

              {personalInfoValues.clearance ? (
                <div className="flex items-center gap-2 bg-[#eeeeee] mb-4 px-4 py-4 rounded-xl flex-wrap max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                  <h2 className="font-semibold text-[14px] max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                    Security Clearance :
                  </h2>
                  <p className="text-[11px] max-[650px]:text-[9px]">
                    {personalInfoValues.clearance}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="w-full flex gap-5 my-6 max-[650px]:my-2">
          <div className="w-full">
            {
              employementData[0] ? (
                <>
                  <h2 className="text-[14px] font-semibold max-[650px]:text-[9px] max-[650px]:leading-[1.2em]   ">
                    Employement History
                  </h2>
                  {employementData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="my-2 text-[11px] flex flex-col gap-2 max-[650px]:text-[9px] max-[650px]:my-2  ">
                        <h3 className="bg-[#535353] px-2 py-2 text-white font-bold text-[11px] max-[650px]:text-[9px] max-[650px]:py-2 max-[650px]:leading-[1.3em]">
                          {item.jobtitle1} | {item.employer} | {item.jobcity}
                        </h3>
                        <h3 className="font-semibold">
                          {item.jobstart} - {item.jobend}
                        </h3>
                        <ul>
                          <li className="max-[650px]:text-[9px]">
                            {item.jobdescription}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </>
              ) : null
            }

            {
              educationData[0] ? (
                <>
                  <h2 className="text-[14px] font-semibold mt-5 max-[650px]:text-[9px] max-[650px]:mt-3 max-[650px]:leading-[1.2em]">
                    Education
                  </h2>
                  {educationData.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="my-2 flex  flex-col text-[11px] gap-2 max-[650px]:text-[9px] max-[650px]:my-2 ">
                        <h3 className="bg-[#535353] px-2 py-2 text-[11px]  text-white font-bold max-[650px]:text-[9px]  max-[650px]:py-2 max-[650px]:leading-[1.3em]">
                          {item.degree} | {item.school} | {item.city1}
                        </h3>
                        <h3 className="font-semibold">
                          {item.start} - {item.end}
                        </h3>
                        <ul>
                          <li>{item.edudescription}</li>
                        </ul>
                      </div>
                    );
                  })}
                </>
              ) : null
            }
            {customSectionData[0] ? (
              <>
                {customSectionData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" flex  flex-col text-[11px] gap-2 max-[650px]:text-[9px] max-[650px]:my-2 ">
                      <h2 className="text-[14px] font-semibold mt-5 max-[650px]:text-[9px] max-[650px]:mt-3 max-[650px]:leading-[1.2em]">
                        {item.sectionTitle}
                      </h2>
                      <h3 className="bg-[#535353] px-2 py-2 text-[11px]  text-white font-bold max-[650px]:text-[9px]  max-[650px]:py-2 max-[650px]:leading-[1.3em]">
                        {item.activity} | {item.customSectionCity}
                      </h3>
                      <h3 className="font-semibold">
                        {item.customStartDate} - {item.customEndDate}
                      </h3>
                      <ul>
                        <li>{item.customDescription}</li>
                      </ul>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <div className=" bg-clearanceGrey w-full items-center gap-5 flex justify-center">
      <PDFDownloadLink
            document={<Resume1Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume1Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
        
      </div>
    </div>
  );
};

export default Resume1;
