import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios"; // You may need to install axios

import {
  Page,
  View,
  Document,
  Text,
  Link,
  Font,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
// import exportAsImage from "./resumecontrolbar";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";

import { Provider } from "react-redux";
import store from "../store";
import { setResumeImage2 } from "../state/reducers/resumeimageSlice";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import Message from "../assets/icons/message-white.png";
import Location from "../assets/icons/location-white.png";
import Phone from "../assets/icons/phone-white.png";

const Resume6 = () => {
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );

  const dispatch = useDispatch();
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage2(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const imageValue = useSelector((state) => state.image.imageValue);
  const navigate = useNavigate();

  const Resume6Download = () => {
    Font.register({
      family: "Roboto-regular",
      src: require("../fonts/Roboto/Roboto-Regular.ttf"),
    });
    Font.register({
      family: "Roboto-semi",
      src: require("../fonts/Roboto/Roboto-Medium.ttf"),
    });

    Font.register({
      family: "Roboto-bold",
      src: require("../fonts/Roboto/Roboto-Bold.ttf"),
    });

    const styles = {
      container: {
        color: colorValue,
        height: "100%",
      },
      flexBox: {
        display: "flex",
        flexDirection: "row-reverse",
        height: "100%",
      },
      con1: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        width: "35%",
        backgroundColor: "#172f53",
        color: "white",
        height: "100%",
      },

      con1Child: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        padding: "15px",
        width: "100%",
        backgroundColor: "#172f53",
        color: "white",
        height: "100%",
      },

      img: {
        width: "100%",
        height: "200px",
      },
      name: {
        fontSize: "14px",
        fontFamily: "Roboto-bold",
      },
      borderTop: {
        width: "15px",
        borderTop: "1px",
        borderColor: "#4d7e76",
      },
      textCon1: {
        fontSize: "9px",
      },
      jobTitle: {
        fontSize: "10px",
        fontFamily: "Roboto-semi",
      },
      subHeadingCon1: {
        fontSize: "12px",
        fontFamily: "Roboto-semi",
      },
      subHeadingSkills: {
        fontSize: "12px",
        fontFamily: "Roboto-semi",
        textDecoration: "underline",
      },
      details: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "100%",
      },
      titleCon1: {
        color: "white",
        fontFamily: "Roboto-semi",
        fontSize: "12px",
      },
      link: {
        color: "white",
        cursor: "pointer",
        fontSize: "9px",
        textDecoration: "none",
      },
      borderBox: {
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        width: "100%",
        alignItems: "center",
      },
      box: {
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        width: "100%",
      },
      skillsText: {
        color: "white",
        fontSize: "9px",
      },
      con2: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "25px",
        width: "65%",
        backgroundColor: "white",
        color: "#4f5259",
        height: "100%",
      },
      boxCon2: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        width: "100%",
      },
      subHeadingCon2: {
        fontSize: "14px",
        fontFamily: "Roboto-semi",
        textDecoration: "underline",
      },
      textCon2: {
        fontSize: "11px",
      },
      titleCon2: {
        fontSize: "11px",
        fontFamily: "Roboto-semi",
      },
      mapBox: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginBottom: "5px",
      },
      detailsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      },
      detailsFlexContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
      },
      detailsImg: {
        width: "15px",
        height: "15px",
      },
    };

    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            <View style={styles.flexBox}>
              <View style={styles.con1}>
                {imageValue ? (
                  <Image src={imageValue} style={styles.img} />
                ) : null}
                <View style={styles.con1Child}>
                  {personalInfoValues.firstName ||
                  personalInfoValues.lastName ? (
                    <Text style={styles.name}>
                      {personalInfoValues.firstName}{" "}
                      {personalInfoValues.lastName}
                    </Text>
                  ) : null}
                  {personalInfoValues.jobTitle ? (
                    <View style={styles.borderBox}>
                      <View style={styles.borderTop}></View>
                      <Text style={styles.jobTitle}>
                        {personalInfoValues.jobTitle}
                      </Text>
                    </View>
                  ) : null}
                  {personalInfoValues.address ||
                  personalInfoValues.dateofbirth ||
                  personalInfoValues.placeofbirth ||
                  personalInfoValues.country ||
                  personalInfoValues.city ||
                  personalInfoValues.phoneNumber ||
                  personalInfoValues.email ||
                  personalInfoValues.drivinglicense ||
                  personalInfoValues.securityClearance ? (
                    <View style={styles.details}>
                      <Text style={styles.subHeadingCon1}>Details</Text>

                      <View style={styles.detailsContainer}>
                        {personalInfoValues.address ||
                        personalInfoValues.city ||
                        personalInfoValues.postalcode ||
                        personalInfoValues.country ? (
                          <View style={styles.box}>
                            <Text style={styles.titleCon1}>Address</Text>
                            <Text style={styles.textCon1}>
                              {personalInfoValues.address}{" "}
                              {personalInfoValues.city}{" "}
                              {personalInfoValues.postalcode}{" "}
                              {personalInfoValues.country}
                            </Text>
                          </View>
                        ) : null}

                        {personalInfoValues.phoneNumber ? (
                          <View style={styles.box}>
                            <Text style={styles.titleCon1}>Phone</Text>
                            <Text style={styles.textCon1}>
                              {personalInfoValues.phoneNumber}
                            </Text>
                          </View>
                        ) : null}

                        {personalInfoValues.email ? (
                          <View style={styles.box}>
                            <Text style={styles.titleCon1}>Email</Text>
                            <Text style={styles.textCon1}>
                              {personalInfoValues.email}
                            </Text>
                          </View>
                        ) : null}
                      </View>

                      {personalInfoValues.nationality ? (
                        <View style={styles.box}>
                          <Text style={styles.titleCon1}>NATIONALITY</Text>
                          <Text style={styles.textCon1}>
                            {personalInfoValues.nationality}
                          </Text>
                        </View>
                      ) : null}
                      {personalInfoValues.drivinglicense ? (
                        <View style={styles.box}>
                          <Text style={styles.titleCon1}>DRIVING LICENSE</Text>
                          <Text style={styles.textCon1}>
                            {personalInfoValues.drivinglicense}
                          </Text>
                        </View>
                      ) : null}
                      {personalInfoValues.dateofbirth ||
                      personalInfoValues.placeofbirth ? (
                        <View style={styles.box}>
                          <Text style={styles.titleCon1}>
                            DATE / PLACE OF BIRTH
                          </Text>
                          <Text style={styles.textCon1}>
                            {personalInfoValues.dateofbirth}
                          </Text>
                          <Text style={styles.textCon1}>
                            {personalInfoValues.placeofbirth}
                          </Text>
                        </View>
                      ) : null}
                      {personalInfoValues.clearance ? (
                        <View style={styles.box}>
                          <Text style={styles.titleCon1}>CLEARANCE</Text>
                          <Text style={styles.textCon1}>
                            {personalInfoValues.clearance}
                          </Text>
                        </View>
                      ) : null}
                    </View>
                  ) : null}
                  {websiteData[0] ? (
                    <View style={styles.box}>
                      <Text style={styles.titleCon1}>LINKS</Text>
                      {websiteData.map((item, index) => (
                        <Link style={styles.link} key={index} src={item.link}>
                          {item.label}
                        </Link>
                      ))}
                    </View>
                  ) : null}
                  {skillsData[0] ? (
                    <View style={styles.box}>
                      <Text style={styles.subHeadingSkills}>SKILLS</Text>
                      {skillsData.map((item, index) => (
                        <Text style={styles.skillsText} key={index}>
                          {item}
                        </Text>
                      ))}
                    </View>
                  ) : null}
                </View>
              </View>
              <View style={styles.con2}>
                {editorValue ? (
                  <View style={styles.boxCon2}>
                    <Text style={styles.subHeadingCon2}>PROFILE</Text>
                    <Text style={styles.textCon2}>{editorValue}</Text>
                  </View>
                ) : null}
                {employementData[0] ? (
                  <View style={styles.boxCon2}>
                    <Text style={styles.subHeadingCon2}>
                      EMPLOYMENT HISTORY
                    </Text>
                    {employementData.map((item, index) => {
                      return (
                        <View key={index} style={styles.mapBox}>
                          <Text style={styles.titleCon2}>
                            {item.jobtitle1} {item.employer} {item.jobcity}
                          </Text>
                          <Text style={styles.textCon2}>
                            {item.jobstart} - {item.jobend}
                          </Text>
                          <Text style={styles.textCon2}>
                            {item.jobdescription}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null}
                {educationData[0] ? (
                  <View style={styles.boxCon2}>
                    <Text style={styles.subHeadingCon2}>EDUCATION</Text>
                    {educationData.map((item, index) => {
                      return (
                        <View key={index} style={styles.mapBox}>
                          <Text style={styles.titleCon2}>
                            {item.school} {item.degree} {item.city1}
                          </Text>
                          <Text style={styles.textCon2}>
                            {item.start} - {item.end}
                          </Text>
                          <Text style={styles.textCon2}>
                            {item.edudescription}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null}
                {customSectionData[0] ? (
                  <View style={styles.boxCon2}>
                    {customSectionData.map((item, index) => {
                      return (
                        <View key={index} style={styles.mapBox}>
                          <Text style={styles.subHeadingCon2}>
                            {item.sectionTitle}
                          </Text>
                          <Text style={styles.titleCon2}>
                            {item.activity} {item.customSectionCity}
                          </Text>
                          <Text style={styles.textCon2}>
                            {item.customStartDate} - {item.customEndDate}
                          </Text>
                          <Text style={styles.textCon2}>
                            {item.customDescription}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                ) : null}
              </View>
            </View>
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] overflow-y-hidden w-[600px] flex flex-col items-center justify-between">
      <div
        ref={containerRef}
        className="flex flex-col overflow-y-scroll hide-scrollbar max-[1100px]:mt-0 w-full max-[650px]:w-full h-full "
      >
        <div className="w-full flex flex-row-reverse h-full relative ">
          <div className="bg-[#172f53] text-white w-[35%] items-center flex flex-col gap-[5px] sm:gap-[15px] text-[7px] sm:text-[9px]">
            {imageValue ? (
              <img
                src={imageValue}
                alt="profile"
                className="w-full h-[200px]"
              />
            ) : null}
            <div className="w-full items-center flex flex-col gap-[15px] text-[7px] sm:text-[9px] p-[15px]">
              {personalInfoValues.firstName || personalInfoValues.lastName ? (
                <h2 className="text-[12px] text-center font-semibold text-white">
                  {personalInfoValues.firstName} {personalInfoValues.lastName}
                </h2>
              ) : null}
              {personalInfoValues.jobTitle ? (
                <>
                  <div className="w-[15px] border-t border-[#4d7e76]"></div>
                  <h3 className="text-[10px] text-white border-b border-white">
                    {personalInfoValues.jobTitle}
                  </h3>
                </>
              ) : null}
              {personalInfoValues.address ||
              personalInfoValues.dateofbirth ||
              personalInfoValues.placeofbirth ||
              personalInfoValues.country ||
              personalInfoValues.city ||
              personalInfoValues.phoneNumber ||
              personalInfoValues.email ||
              personalInfoValues.drivinglicense ||
              personalInfoValues.securityClearance ? (
                <div className="w-full flex flex-col gap-[15px]">
                  <h2 className="font-semibold text-[10px] sm:text-[12px]">
                    Details
                  </h2>
                  <div className="flex flex-col gap-[5px]">
                    {personalInfoValues.address ||
                    personalInfoValues.city ||
                    personalInfoValues.postalcode ||
                    personalInfoValues.country ? (
                      <div className="flex flex-col gap-[5px]">
                        <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                          Address
                        </h3>
                        <p>
                          {personalInfoValues.address} {personalInfoValues.city}{" "}
                          {personalInfoValues.postalcode}{" "}
                          {personalInfoValues.country}
                        </p>
                      </div>
                    ) : null}

                    {personalInfoValues.phoneNumber ? (
                      <div className="flex flex-col gap-[5px] ">
                        <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                          Phone
                        </h3>
                        <p>{personalInfoValues.phoneNumber}</p>
                      </div>
                    ) : null}
                    {personalInfoValues.email ? (
                      <div className="flex gap-[5px] flex-col">
                        <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                          Email
                        </h3>
                        <p>{personalInfoValues.email}</p>
                      </div>
                    ) : null}
                  </div>
                  {personalInfoValues.nationality ? (
                    <div className="flex flex-col gap-[3px]">
                      <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                        NATIONALITY
                      </h3>
                      <p>{personalInfoValues.nationality}</p>
                    </div>
                  ) : null}
                  {personalInfoValues.drivinglicense ? (
                    <div className="flex flex-col gap-[3px]">
                      <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                        DRIVING LICENSE
                      </h3>
                      <p>{personalInfoValues.drivinglicense}</p>
                    </div>
                  ) : null}
                  {personalInfoValues.dateofbirth ||
                  personalInfoValues.placeofbirth ? (
                    <div className="flex flex-col gap-[3px]">
                      <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                        DATE / PLACE OF BIRTH
                      </h3>
                      <p>{personalInfoValues.dateofbirth}</p>
                      <p>{personalInfoValues.placeofbirth}</p>
                    </div>
                  ) : null}
                  {personalInfoValues.clearance ? (
                    <div className="flex flex-col gap-[3px]">
                      <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                        CLEARANCE
                      </h3>
                      <p>{personalInfoValues.clearance}</p>
                    </div>
                  ) : null}
                  {websiteData[0] ? (
                    <div className="flex flex-col gap-[3px]">
                      <h3 className="text-[10px] sm:text-[12px] font-semibold text-white">
                        LINKS
                      </h3>
                      {websiteData.map((item, index) => {
                        return (
                          <h3
                            key={index}
                            onClick={() =>
                              window.open(`//${item.link}`, "_blank")
                            }
                            className=" cursor-pointer text-[11px] max-[650px]:text-[9px]"
                          >
                            {item.label}
                          </h3>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="w-full flex flex-col gap-[5px]">
                {skillsData[0] ? (
                  <>
                    <h2 className="text-[10px] sm:text-[12px] font-semibold underline pb-1">
                      Skills
                    </h2>
                    {skillsData.map((item, index) => {
                      return (
                        <p key={index} className="font-medium">
                          {item}
                        </p>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="bg-[#f5f5f5] h-full w-[65%] text-[7px] sm:text-[11px] p-[25px]">
            {editorValue ? (
              <div className="flex flex-col items-start gap-[5px]">
                <h2 className="text-[#4f5259] border-b border-black text-[12px] sm:text-[14px] font-semibold">
                  PROFILE
                </h2>
                <p className="sm:text-[11px]">{editorValue}</p>
              </div>
            ) : null}
            {employementData[0] ? (
              <div className="my-2 flex flex-col items-start gap-[5px]">
                <h2 className="text-[#4f5259] border-b border-black text-[12px] sm:text-[14px] font-semibold">
                  EMPLOYMENT HISTORY
                </h2>
                {employementData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-col mb-2 gap-1"
                    >
                      <p className="font-semibold text-[10px] sm:text-[12px]">
                        {item.jobtitle1} {item.employer} {item.jobcity}
                      </p>
                      <p className=" font-semibold">
                        {item.jobstart} - {item.jobend}
                      </p>
                      <p className="font-medium ">{item.jobdescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {educationData[0] ? (
              <div className="my-2 flex flex-col gap-[5px] items-start">
                <h2 className="text-[#4f5259] border-b border-black text-[12px] sm:text-[14px] font-semibold">
                  EDUCATION
                </h2>
                {educationData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-col mb-2 gap-1"
                    >
                      <p className="font-semibold text-[10px] sm:text-[12px]">
                        {item.school} {item.degree} {item.city1}
                      </p>
                      <p className="font-semibold">
                        {item.start} - {item.end}
                      </p>
                      <p className="font-medium">{item.edudescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {customSectionData[0] ? (
              <div>
                {customSectionData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-col items-start my-2 gap-1"
                    >
                      <h2 className="text-[#4f5259] border-b border-black text-[12px] sm:text-[14px]  font-semibold">
                        {item.sectionTitle}
                      </h2>
                      <p className="font-semibold text-[10px] sm:text-[12px]">
                        {item.activity} {item.customSectionCity}
                      </p>
                      <p className=" font-semibold">
                        {item.customStartDate} - {item.customEndDate}
                      </p>
                      <p className="font-medium ">{item.customDescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className=" bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        <PDFDownloadLink
          document={<Resume6Download />}
          style={{
            textDecoration: "none",
            padding: "5px 8px",
            color: "black",
            fontWeight: 500,
            backgroundColor: "white",
            border: "1px solid #4A90E2",
            borderRadius: "4px",
            margin: "10px 0",
          }}
        >
          Download PDF
        </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume6Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Resume6;
