import React, { useEffect, useState } from "react";
import axios from "axios";

const VerifyEmailPage = () => {
  const [status, setStatus] = useState("verifying"); // Possible values: 'verifying', 'success', 'error'
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Extract email and token from URL
    const pathParts = window.location.pathname.split("/").slice(-2);
    const email = pathParts[0];
    const token = pathParts[1];
    console.log(email, token);
    axios
      .get(`/api/v1/verify/${email}/${token}`)
      .then((response) => {
        // Handle success response
        console.log(response);
        setStatus("success");
      })
      .catch((error) => {
        // Handle error response
        setStatus("error");
        setErrorMessage(
          error.response?.data?.message || "Error occurred during verification."
        );
      });
  }, []);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="p-6 bg-white shadow-md rounded">
        {status === "verifying" && <p>Verifying...</p>}

        {status === "success" && (
          <p className="text-green-600">Verification Complete!</p>
        )}

        {status === "error" && <p className="text-red-600">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default VerifyEmailPage;
