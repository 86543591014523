import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ProfilePage = () => {
  const [dataProfile, setProfileData] = useState([]);
  const [address, setAddress] = useState([]);
  const [Download, setDownload] = useState([]);
  const [ai, setAi] = useState([]);
  const navigate = useNavigate();
  const [isButtonVisibleFree, setIsButtonVisibleFree] = useState(false);
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.setItem("");
    navigate("/");
  };
  const fetchuser = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios({
        method: "get",
        url: `/api/v1/getUser`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          setProfileData(res.data.userData);
          if (res.data.userData.plan === "basic") {
            setIsButtonVisibleFree(true);
          }
          setAddress(res.data.template);
          if (res.data.userData.downloads_remaining > 2) {
            setDownload("infite");
          } else if (res.data.userData.downloads_remaining <= 2) {
            setDownload(res.data.userData.downloads_remaining);
          }
          if (res.data.userData.ai_usage_remaining >= 6) {
            setAi(res.data.userData.ai_usage_remaining);
          } else {
            setAi("infine");
          }
        })
        .catch((err) => {
          navigate("/auth/login");

          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");

    fetchuser();
  }, []);
  const [profile, setProfile] = useState(false);
  const handleCancelPlan = () => {
    const token = localStorage.getItem("authToken");

    try {
      axios({
        method: "post",
        url: `/api/v1/cancel-subscription`,
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        navigate("/dashboard");
      });
    } catch (error) {
      showToast(error);
    }
  };
  const showPorfile = () => {
    setProfile((prev) => !prev);
  };
  const animDiv = {
    hidden: {
      opacity: 0,
      x: -150,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        mass: 0.5,
        damping: 1,
      },
    },
  };
  return (
    <>
      <motion.div
        variants={animDiv}
        initial="hidden"
        animate="visible"
        className="w-full px-[10px] sm:px-[30px] py-[30px]  h-[1000px] sm:h-full">
        <Link
          to="/dashboard"
          className="px-4 py-3 bg-clearanceDarkBlue rounded-md text-white">
          <button>Return To DashBoard</button>
        </Link>

        <div className=" sm:mt-10 ">
          <div className="p-4 flex flex-row items-center justify-between">
            <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline"></button>
          </div>
          <nav className="flex-col flex-grow pb-4 md:pb-0 hidden md:flex md:justify-end md:flex-row">
            <div className="relative">
              <button
                onClick={showPorfile}
                className="flex flex-row items-center text-white space-x-2 w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-clearanceGrey md:w-auto md:inline md:mt-0 md:ml-4 rounded-md hover:bg-clearanceDarkBlue focus:outline-none focus:shadow-outline">
                <span>options</span>
              </button>

              {/* modal */}
              {profile ? (
                <div className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
                  <div className="py-2 bg-white text-blue-800 text-sm rounded-sm border border-main-color shadow-sm">
                    {/* <Link
                      className="block px-4 py-2 mt-2 text-sm bg-white md:mt-0 focus:text-gray-900 hover:bg-indigo-100 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      to="/dashboard/payment">
                      Payments
                    </Link> */}

                    <div className="border-b"></div>
                    <a
                      className="block px-4 py-2 mt-2 text-sm bg-white md:mt-0 focus:text-gray-900 hover:bg-indigo-100 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      href="/"
                      onClick={handleLogout}>
                      Logout
                    </a>
                    <div className="border-b"></div>
                    <a>
                      {isButtonVisibleFree ? (
                        <h1></h1>
                      ) : (
                        <button
                          onClick={() => {
                            handleCancelPlan();
                          }}
                          className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white">
                          cancel plan
                        </button>
                      )}
                    </a>
                  </div>
                </div>
              ) : null}

              {/* model of logout */}
            </div>
          </nav>

          <div className="container h-full mx-auto sm:my-5 p-2">
            <div className="lg:flex-row flex gap-[10px] sm:gap-[30px] no-wrap flex-col ">
              <div className="w-full lg:w-3/12 ">
                <div className="bg-white p-3 border-t-4 border-clearanceDarkBlue">
                  <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                    {dataProfile.name}
                  </h1>

                  <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                    <li className="flex items-center py-3">
                      <span>Status</span>
                      <span className="ml-auto">
                        <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                          Active
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="my-4"></div>
              </div>
              <div className="w-full lg:w-9/12 mx-2 h-64">
                <div className="bg-white p-3 shadow-sm rounded-sm">
                  <div className="flex items-center justify-center mb-2 space-x-2 font-semibold text-gray-900 leading-8">
                    <span className="tracking-wide">About</span>
                  </div>
                  <div className="text-gray-700">
                    <div className="grid md:grid-cols-2 text-sm">
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Name</div>
                        <div className="px-4 py-2 bg-grey">
                          <p>{dataProfile.name}</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Email</div>
                        <p>{dataProfile.email}</p>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Plan</div>
                        <div className="px-4 py-2">
                          <p>{dataProfile.plan}</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">verified</div>
                        <div className="px-4 py-2">
                          <p>true</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">
                          Birth date
                        </div>
                        <div className="px-4 py-2">
                          <p>{address.dateofbirth}</p>
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">
                          Permanant Address
                        </div>
                        <div className="px-4 py-2">
                          <p>{address.address}</p>{" "}
                        </div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">
                          Downloads Remaining
                        </div>
                        <div className="px-4 py-2">{Download}</div>
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">
                          Ai Usage Remaining
                        </div>
                        <div className="px-4 py-2">{ai}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ProfilePage;
