import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  PDFDownloadLink,
  Font,
  Image,
} from "@react-pdf/renderer";
import Message1 from "../assets/icons/message1.png";
import Location from "../assets/icons/location.png";
import Phone from "../assets/icons/phone.png";
import exportAsImage from "./resumecontrolbar";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";
import { Provider } from "react-redux";
import store from "../store";
import { setResumeImage3 } from "../state/reducers/resumeimageSlice";
import { generate1 } from "./resume3Word";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { FaLocationPin, FaMessage, FaPhone } from "react-icons/fa6";
const Resume3 = () => {
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage3(imageData));
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [3000]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const imageValue = useSelector((state) => state.image.imageValue);

  const Resume3Download = () => {
    Font.register({
      family: "Roboto-regular",
      src: require("../fonts/Roboto/Roboto-Regular.ttf"),
    });
    Font.register({
      family: "Roboto-semi",
      src: require("../fonts/Roboto/Roboto-Medium.ttf"),
    });

    Font.register({
      family: "Roboto-bold",
      src: require("../fonts/Roboto/Roboto-Bold.ttf"),
    });

    const styles = {
      mainContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      childCon1: {
        width: "100%",
        backgroundColor: "#303030",
        color: "white",
        fontSize: "9px",
      },
      section1: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "7px",
        padding: "20px",
      },
      img: {
        width: "70px",
        height: "70px",
        borderRadius: "50px",
      },
      name: {
        fontSize: "18px",
        fontFamily: "Roboto-bold",
      },
      jobtitle: {
        fontSize: "12px",
        textDecoration: "underline",
        fontFamily: "Roboto-semi",
      },
      detailsTitle: {
        fontFamily: "Roboto-semi",
      },
      section2: {
        width: "100%",
        padding: "15px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "15px",
        alignItems: "center",
        borderTop: "1px",
        borderColor: "#4b4b4b",
      },
      section2Box: {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
      },
      childCon2: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "white",
      },
      sectionCon2: {
        width: "70%",
        fontSize: "9px",
        paddingTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      },
      boxCon2: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        fontSize: "9px",
      },
      childCon2Title: {
        fontSize: "14px",
        fontFamily: "Roboto-semi",
        textDecoration: "underline",
      },
      childSectionCon2: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "12px",
        fontSize: "9px",
      },
      mapCon: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        fontSize: "9px",
      },
      childSectionCon2Box: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        fontSize: "9px",
      },
      LinkCon: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "7px",
        fontSize: "9px",
      },
      linkText: {
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "9px",
        color: "#303030",
      },
      skillsText: {
        width: "48%",
        fontFamily: "Roboto-regular",
      },
      icons: {
        width: "10px",
        height: "10px",
      },
    };

    return (
      <Provider store={store}>
        <Document>
          <Page size="A4">
            <View style={styles.mainContainer}>
              <View style={styles.childCon1}>
                <View style={styles.section1}>
                  {imageValue ? (
                    <Image src={imageValue} style={styles.img} />
                  ) : null}
                  {personalInfoValues.firstName ||
                  personalInfoValues.lastName ? (
                    <Text style={styles.name}>
                      {personalInfoValues.firstName}{" "}
                      {personalInfoValues.lastName}
                    </Text>
                  ) : null}
                  {personalInfoValues.jobTitle ? (
                    <Text style={styles.jobtitle}>
                      {personalInfoValues.jobTitle}
                    </Text>
                  ) : null}
                </View>
                {personalInfoValues.address ||
                personalInfoValues.city ||
                personalInfoValues.country ||
                personalInfoValues.postalcode ||
                personalInfoValues.phoneNumber ? (
                  <View style={styles.section2}>
                    {personalInfoValues.email ? (
                      <View style={styles.section2Box}>
                        <Image src={Message1} style={styles.icons} />
                        <Text>{personalInfoValues.email}</Text>
                      </View>
                    ) : null}
                    {personalInfoValues.address ||
                    personalInfoValues.city ||
                    personalInfoValues.postalcode ||
                    personalInfoValues.country ? (
                      <View style={styles.section2Box}>
                        <Image src={Location} style={styles.icons} />
                        <Text>
                          {personalInfoValues.address} {personalInfoValues.city}{" "}
                          {personalInfoValues.postalcode}{" "}
                          {personalInfoValues.country}
                        </Text>
                      </View>
                    ) : null}
                    {personalInfoValues.phoneNumber ? (
                      <View style={styles.section2Box}>
                        <Image src={Phone} style={styles.icons} />
                        <Text>{personalInfoValues.phoneNumber}</Text>
                      </View>
                    ) : null}
                  </View>
                ) : null}
              </View>
              <View style={styles.childCon2}>
                <View style={styles.sectionCon2}>
                  <View style={styles.boxCon2}>
                    {personalInfoValues.drivinglicense ||
                    personalInfoValues.nationality ||
                    personalInfoValues.dateofbirth ||
                    personalInfoValues.placeofbirth ? (
                      <>
                        <Text style={styles.childCon2Title}>Details</Text>
                        <View style={styles.childSectionCon2}>
                          {personalInfoValues.drivinglicense ? (
                            <View style={styles.childSectionCon2Box}>
                              <Text style={styles.detailsTitle}>
                                Driving License
                              </Text>
                              <Text>{personalInfoValues.drivinglicense}</Text>
                            </View>
                          ) : null}
                          {personalInfoValues.nationality ? (
                            <View style={styles.childSectionCon2Box}>
                              <Text style={styles.detailsTitle}>
                                Nationality
                              </Text>
                              <Text>{personalInfoValues.nationality}</Text>
                            </View>
                          ) : null}
                          {personalInfoValues.dateofbirth ||
                          personalInfoValues.placeofbirth ? (
                            <View style={styles.childSectionCon2Box}>
                              <Text style={styles.detailsTitle}>
                                Date / Place of Birth
                              </Text>
                              <Text>
                                {personalInfoValues.dateofbirth} /{" "}
                                {personalInfoValues.placeofbirth}
                              </Text>
                            </View>
                          ) : null}
                        </View>
                      </>
                    ) : null}
                  </View>
                  {editorValue ? (
                    <View style={styles.boxCon2}>
                      <Text style={styles.childCon2Title}>Profile</Text>
                      <Text>{editorValue}</Text>
                    </View>
                  ) : null}
                  {personalInfoValues.clearance ? (
                    <View style={styles.boxCon2}>
                      <Text style={styles.childCon2Title}>Clearance</Text>
                      <Text>{personalInfoValues.clearance}</Text>
                    </View>
                  ) : null}
                  {employementData[0] ? (
                    <View style={styles.mapCon}>
                      <Text style={styles.childCon2Title}>
                        Employment History
                      </Text>
                      {employementData.map((item, index) => {
                        return (
                          <View style={styles.boxCon2} key={index}>
                            <Text style={styles.detailsTitle}>
                              {item.jobtitle1} {item.employer} {item.jobcity}
                            </Text>
                            <Text style={styles.detailsTitle}>
                              {item.jobstart} - {item.jobend}
                            </Text>
                            <Text>{item.jobdescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  ) : null}
                  {educationData[0] ? (
                    <View style={styles.mapCon}>
                      <Text style={styles.childCon2Title}>Education</Text>
                      {educationData.map((item, index) => {
                        return (
                          <View style={styles.boxCon2} key={index}>
                            <Text style={styles.detailsTitle}>
                              {item.degree} {item.school} {item.city1}
                            </Text>
                            <Text style={styles.detailsTitle}>
                              {item.start} - {item.end}
                            </Text>
                            <Text>{item.edudescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  ) : null}
                  {websiteData[0] ? (
                    <View style={styles.mapCon}>
                      <Text style={styles.childCon2Title}>Links</Text>
                      <View style={styles.LinkCon}>
                        {websiteData.map((item, index) => {
                          return (
                            <Link
                              style={styles.linkText}
                              key={index}
                              src={item.link}
                            >
                              {item.label}
                            </Link>
                          );
                        })}
                      </View>
                    </View>
                  ) : null}
                  {skillsData[0] ? (
                    <View style={styles.mapCon}>
                      <Text style={styles.childCon2Title}>Skills</Text>
                      <View style={styles.LinkCon}>
                        {skillsData.map((item, index) => {
                          return (
                            <Text key={index} style={styles.skillsText}>
                              {item}
                            </Text>
                          );
                        })}
                      </View>
                    </View>
                  ) : null}
                  {customSectionData[0] ? (
                    <View style={styles.mapCon}>
                      {customSectionData.map((item, index) => {
                        return (
                          <View style={styles.boxCon2} key={index}>
                            <Text style={styles.childCon2Title}>
                              {item.sectionTitle}
                            </Text>
                            <Text style={styles.detailsTitle}>
                              {item.activity} {item.customSectionCity}
                            </Text>
                            <Text style={styles.detailsTitle}>
                              {item.customStartDate} - {item.customEndDate}
                            </Text>
                            <Text>{item.customDescription}</Text>
                          </View>
                        );
                      })}
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center justify-between">
      <div
        ref={containerRef}
        className="flex flex-col  overflow-y-scroll hide-scrollbar  max-[1100px]:mt-0 h-full w-full max-[650px]:w-full"
      >
        <div className="w-full text-[7px] bg-[#303030] text-white ">
          <div className="w-full flex flex-col gap-[7px] items-center p-[20px]">
            {imageValue ? (
              <img
                src={imageValue}
                alt="profile"
                className="w-[70px] h-[70px] rounded-[50px]"
              />
            ) : null}
            {personalInfoValues.firstName || personalInfoValues.lastName ? (
              <h2 className="text-[14px] sm:text-[18px] font-semibold">
                {personalInfoValues.firstName} {personalInfoValues.lastName}
              </h2>
            ) : null}
            {personalInfoValues.jobTitle ? (
              <h3 className="font-medium text-[10px] sm:text-[12px] underline">
                {personalInfoValues.jobTitle}
              </h3>
            ) : null}
          </div>
          {personalInfoValues.address ||
          personalInfoValues.city ||
          personalInfoValues.country ||
          personalInfoValues.postalcode ||
          personalInfoValues.phoneNumber ? (
            <div className="w-full border-t border-[#4b4b4b] p-[7px] sm:p-[15px] flex flex-wrap sm:flex-nowrap justify-center gap-[20px] items-center">
              {personalInfoValues.email ? (
                <div className="flex gap-[5px] items-center">
                  <img
                    src={Message1}
                    alt="message"
                    className="w-[10px] h-[10px]"
                  />
                  <p>{personalInfoValues.email}</p>
                </div>
              ) : null}
              {personalInfoValues.address ||
              personalInfoValues.city ||
              personalInfoValues.postalcode ||
              personalInfoValues.country ? (
                <div className="flex gap-[5px] items-center">
                  <img
                    src={Location}
                    alt="message"
                    className="w-[10px] h-[10px]"
                  />
                  <p>
                    {personalInfoValues.address} {personalInfoValues.city}{" "}
                    {personalInfoValues.postalcode} {personalInfoValues.country}
                  </p>
                </div>
              ) : null}
              {personalInfoValues.phoneNumber ? (
                <div className="flex gap-[5px] items-center">
                  <img
                    src={Phone}
                    alt="message"
                    className="w-[10px] h-[10px]"
                  />
                  <p>{personalInfoValues.phoneNumber}</p>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="w-full flex justify-center bg-white">
          <div className="w-[80%] sm:w-[70%] text-[7px] sm:text-[9px] pt-[30px] flex flex-col gap-[8px]">
            <div className="w-full flex flex-col gap-[4px]">
              {personalInfoValues.drivinglicense ||
              personalInfoValues.nationality ||
              personalInfoValues.dateofbirth ||
              personalInfoValues.placeofbirth ? (
                <>
                  <h2 className="text-[12px] sm:text-[14px] font-semibold underline">
                    Details
                  </h2>
                  <div className="w-full flex gap-[12px] flex-wrap">
                    {personalInfoValues.drivinglicense ? (
                      <div className="w-[30%] flex flex-col gap-[4px]">
                        <h3 className="font-semibold">Driving License</h3>
                        <p>{personalInfoValues.drivinglicense}</p>
                      </div>
                    ) : null}
                    {personalInfoValues.nationality ? (
                      <div className="w-[30%] flex flex-col gap-[4px]">
                        <h3 className="font-semibold">Nationality</h3>
                        <p>{personalInfoValues.nationality}</p>
                      </div>
                    ) : null}
                    {personalInfoValues.dateofbirth ||
                    personalInfoValues.placeofbirth ? (
                      <div className="w-[30%] flex flex-col gap-[4px]">
                        <h3 className="font-semibold">Date / Place of Birth</h3>
                        <p>
                          {personalInfoValues.dateofbirth} /{" "}
                          {personalInfoValues.placeofbirth}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
            {editorValue ? (
              <div className="flex flex-col gap-[4px] w-full">
                <h2 className="text-[12px] sm:text-[14px] font-semibold underline">
                  Profile
                </h2>
                <p>{editorValue}</p>
              </div>
            ) : null}
            {personalInfoValues.clearance ? (
              <div className="flex flex-col gap-[4px] w-full">
                <h2 className="text-[12px] sm:text-[14px] font-semibold underline">
                  Clearance
                </h2>
                <p>{personalInfoValues.clearance}</p>
              </div>
            ) : null}
            {employementData[0] ? (
              <div className="flex flex-col gap-[5px]">
                <h2 className="text-[12px] sm:text-[14px] font-semibold">
                  Employment History
                </h2>
                {employementData.map((item, index) => {
                  return (
                    <div key={index} className="w-full flex flex-col gap-[4px]">
                      <p className="font-semibold">
                        {item.jobtitle1} {item.employer} {item.jobcity}
                      </p>
                      <p className="font-semibold">
                        {item.jobstart} - {item.jobend}
                      </p>
                      <p>{item.jobdescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {educationData[0] ? (
              <div className="flex flex-col gap-[5px]">
                <h2 className="text-[12px] sm:text-[14px] font-semibold underline">
                  Education
                </h2>
                {educationData.map((item, index) => {
                  return (
                    <div key={index} className="w-full flex flex-col gap-[4px]">
                      <p className="font-semibold">
                        {item.degree} {item.school} {item.city1}
                      </p>
                      <p className="font-semibold">
                        {item.start} - {item.end}
                      </p>
                      <p>{item.edudescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {websiteData[0] ? (
              <>
                <h3 className="text-[12px] sm:text-[14px] font-semibold underline">
                  Links
                </h3>
                <div className="flex gap-[7px] items-center flex-wrap">
                  {websiteData.map((item, index) => {
                    return (
                      <h3
                        key={index}
                        onClick={() => window.open(`//${item.link}`, "_blank")}
                        className=" cursor-pointer text-[9px] max-[650px]:text-[7px]"
                      >
                        {item.label}
                      </h3>
                    );
                  })}
                </div>
              </>
            ) : null}
            {skillsData[0] ? (
              <>
                <h2 className="text-[12px] sm:text-[14px] font-semibold underline">
                  Skills
                </h2>
                <div className="flex gap-[7px] items-center flex-wrap">
                  {skillsData.map((item, index) => {
                    return (
                      <p key={index} className="w-[48%] font-medium">
                        {item}
                      </p>
                    );
                  })}
                </div>
              </>
            ) : null}
            {customSectionData[0] ? (
              <div className="flex flex-col mt-1 gap-[5px]">
                {customSectionData.map((item, index) => {
                  return (
                    <div key={index} className="w-full flex flex-col gap-[4px]">
                      <h2 className="text-[12px] sm:text-[14px] underline font-semibold">
                        {item.sectionTitle}
                      </h2>
                      <p>
                        {item.activity} {item.customSectionCity}
                      </p>
                      <p>
                        {item.customStartDate} - {item.customEndDate}
                      </p>
                      <p>{item.customDescription}</p>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        <PDFDownloadLink
          document={<Resume3Download />}
          style={{
            textDecoration: "none",
            padding: "5px 8px",
            color: "black",
            fontWeight: 500,
            backgroundColor: "white",
            border: "1px solid #4A90E2",
            borderRadius: "4px",
            margin: "10px 0",
          }}
        >
          Download PDF
        </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume3Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Resume3;
