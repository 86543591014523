import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios"; // You may need to install axios

import {
  Page,
  View,
  Document,
  Text,
  Link,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import exportAsImage from "./resumecontrolbar";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";

import { Provider } from "react-redux";
import store from "../store";
import { setResumeImage2 } from "../state/reducers/resumeimageSlice";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
const Resume7 = () => {
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );

  const dispatch = useDispatch();
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage2(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const navigate = useNavigate();

  const Resume7Download = () => {
    Font.register({
      family: "Poppins-regular",
      src: require("../fonts/Poppins/Poppins-Regular.ttf"),
    });
    Font.register({
      family: "Poppins-semi",
      src: require("../fonts/Poppins/Poppins-SemiBold.ttf"), // Make sure to provide the correct path to your font file.
    });

    Font.register({
      family: "Poppins-bold",
      src: require("../fonts/Poppins/Poppins-Bold.ttf"), // Make sure to provide the correct path to your font file.
    });

    const styles = {
      container: {
        padding: 30,
        color: colorValue,
      },
      header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "16px",
      },
      name: {
        fontSize: 17,
        fontFamily: "Poppins-bold",
        marginBottom: "12px",
      },
      details: {
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "8px",
        fontSize: 11,
        fontFamily: "Poppins-regular",
      },
      text: {
        fontSize: 11,
        marginBottom: "2px",
        fontFamily: "Poppins-regular",
      },
      subheading: {
        fontSize: 11,
        fontFamily: "Poppins-semi",
      },
      jobTitle: {
        fontSize: 11,
        fontFamily: "Poppins-semi",
        marginRight: 20,
      },
      section: {
        borderLeftWidth: "4px",
        padding: "8px",
        marginBottom: "16px",
      },
      title: {
        textDecoration: "underline",
        fontSize: 14,
        fontFamily: "Poppins-bold",
        marginBottom: "8px",
      },
      skills: {
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "10px",
      },
      skill: {
        fontSize: 11,
        fontFamily: "Poppins-semi",
      },
      link: {
        textDecoration: "underline",
        fontSize: 11,
        color: "black",
      },
      employment: {
        marginBottom: "0px",
      },
      employmentHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "0px",
      },
      employmentTitle: {
        fontSize: 18,
        fontWeight: "semibold",
      },
      education: {
        marginBottom: "16px",
        fontSize: 11,
      },
    };

    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            <View style={styles.header}>
              <View>
                <Text style={styles.name}>
                  {personalInfoValues.firstName} {personalInfoValues.lastName}
                </Text>
                <View style={styles.details}>
                  <Text>{personalInfoValues.phoneNumber}</Text>
                  <Text>{personalInfoValues.email}</Text>
                  <Text>
                    {personalInfoValues.city} {personalInfoValues.country}
                  </Text>
                  <Text>{personalInfoValues.address}</Text>
                  <Text>{personalInfoValues.postalcode}</Text>
                  <Text>{personalInfoValues.drivinglicense}</Text>
                  <Text>{personalInfoValues.nationality}</Text>
                  <Text>{personalInfoValues.placeofbirth}</Text>
                  <Text>{personalInfoValues.dateofbirth}</Text>
                </View>
              </View>

              <Text style={styles.jobTitle}>{personalInfoValues.jobTitle}</Text>
            </View>
            {editorValue ? (
              <View style={{ ...styles.section, borderColor: "black" }}>
                <Text style={styles.title}>Summary</Text>
                <Text style={styles.text}>{editorValue}</Text>
              </View>
            ) : null}

            {skillsData[0] ? (
              <View style={{ ...styles.section, borderColor: "darkGray" }}>
                <Text style={styles.title}>Areas of Expertise</Text>
                <View style={styles.skills}>
                  {skillsData.map((item, index) => (
                    <Text style={styles.skill} key={index}>
                      {item}
                    </Text>
                  ))}
                </View>
              </View>
            ) : null}
            {websiteData[0] ? (
              <View style={{ ...styles.section, borderColor: "darkGray" }}>
                <Text style={styles.title}>Links</Text>
                <View style={styles.skills}>
                  {websiteData.map((item, index) => (
                    <Link style={styles.link} key={index} src={item.link}>
                      {item.label}
                    </Link>
                  ))}
                </View>
              </View>
            ) : null}
            {employementData[0] ? (
              <View style={{ ...styles.section, borderColor: "gray" }}>
                <Text style={styles.title}>Professional Summary</Text>
                {employementData.map((item, index) => (
                  <View key={index} style={styles.employment}>
                    <View style={styles.employmentHeader}>
                      <Text style={styles.subheading}>
                        {item.employer}, {item.jobcity}
                      </Text>
                      <Text style={styles.text}>
                        {item.jobstart} - {item.jobend}
                      </Text>
                    </View>
                    <Text style={styles.subheading}>{item.jobtitle1}</Text>
                    <Text style={styles.text}>{item.jobdescription}</Text>
                  </View>
                ))}
              </View>
            ) : null}
            {educationData[0] ? (
              <View style={{ ...styles.section, borderColor: "gray" }}>
                <Text style={styles.title}>Education</Text>
                {educationData.map((item, index) => (
                  <View key={index} style={styles.education}>
                    <View style={styles.employmentHeader}>
                      <Text style={styles.subheading}>
                        {item.school}, {item.city1}
                      </Text>
                      <Text style={styles.subheading}>
                        {item.start} - {item.end}
                      </Text>
                    </View>
                    <Text style={styles.subheading}>{item.degree}</Text>
                    <Text style={styles.text}>{item.edudescription}</Text>
                  </View>
                ))}
              </View>
            ) : null}
            {customSectionData[0] ? (
              <View style={{ ...styles.section, borderColor: "gray" }}>
                {customSectionData.map((item, index) => (
                  <View key={index} style={styles.education}>
                    <Text style={styles.title}>{item.sectionTitle}</Text>
                    <View style={styles.employmentHeader}>
                      <Text style={styles.subheading}>
                        {item.activity}, {item.customSectionCity}
                      </Text>
                      <Text style={styles.subheading}>
                        {item.customStartDate} - {item.customEndDate}
                      </Text>
                    </View>

                    <Text style={styles.text}>{item.customDescription}</Text>
                  </View>
                ))}
              </View>
            ) : null}
            {personalInfoValues.clearance ? (
              <View style={{ ...styles.section, borderColor: "gray" }}>
                <Text style={styles.title}>Security Clearance:</Text>
                <Text style={styles.text}>{personalInfoValues.clearance}</Text>
              </View>
            ) : null}
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center justify-between">
      <div
        ref={containerRef}
        className="flex flex-col overflow-y-scroll hide-scrollbar max-[1100px]:mt-0 w-full max-[650px]:w-full h-full p-[20px]"
      >
        <div className="flex justify-between">
          <div className="flex flex-col w-3/4 ">
            <h1 className="text-2xl font-bold max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              {personalInfoValues.firstName} {personalInfoValues.lastName}{" "}
            </h1>
            <div className="flex gap-2 mt-2 flex-wrap text-[11px]">
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.phoneNumber}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.email}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.city} {personalInfoValues.country}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.address}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.postalcode}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.drivinglicense}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.nationality}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.placeofbirth}
              </p>
              <p className="max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                {personalInfoValues.dateofbirth}
              </p>
            </div>
          </div>
          <div className="w-1/4  ">
            <h3 className="text-[14px] font-semibold text-center  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              {personalInfoValues.jobTitle}
            </h3>
          </div>
        </div>
        {editorValue ? (
          <div className="w-full border-l-8 border-black px-3 my-4 max-[650px]:my-2 py-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4">
            <h2 className="mb-1">
              <u className="font-semibold text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                Summary
              </u>
            </h2>
            <p className="max-[650px]:text-[9px]  text-[11px] max-[650px]:leading-[1.2em]">
              {editorValue}
            </p>
          </div>
        ) : null}
        {skillsData[0] ? (
          <div className="w-full border-l-8 border-gray-700 px-3 my-4 max-[650px]:my-2 py-2 max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4 ">
            <h2 className="mb-1">
              <u className="font-semibold text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                Areas of Expertise
              </u>
            </h2>
            <div className="flex gap-x-4 max-[650px]:gap-x-2 gap-1 flex-wrap text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
              {skillsData.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] "
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}
        {websiteData[0] ? (
          <div className="w-full border-l-8 border-gray-600 px-3 my-4 py-2  max-[650px]:my-2  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4 text-[14px]">
            <h2 className="font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
              Links
            </h2>
            <div className="flex text-[11px] flex-wrap gap-x-4 gap-1 max-[650px]:gap-x-2">
              {websiteData.map((item, index) => {
                return (
                  <h3
                    key={index}
                    onClick={() => window.open(`//${item.link}`, "_blank")}
                    className="underline flex pb-1 max-[650px]:text-[9px]"
                  >
                    {item.label}
                  </h3>
                );
              })}
            </div>
          </div>
        ) : null}
        {employementData[0] ? (
          <div className="w-full border-l-8 border-gray-600 px-3 my-4 py-2 max-[650px]:my-2  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4 text-[11px]">
            <h2 className="mb-1 text-[14px]">
              <u className="font-semibold max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                Professional Summary
              </u>
            </h2>
            {employementData.map((item, index) => {
              return (
                <div key={index} className="flex text-[11px] flex-col my-2">
                  <div className="flex justify-between w-full mb-1 max-[650px]:text-[9px]">
                    <h3 className="font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      {item.employer}, {item.jobcity}{" "}
                    </h3>
                    <h3>
                      {item.jobstart} - {item.jobend}
                    </h3>
                  </div>
                  <div className="max-[650px]:text-[9px]">
                    <h3 className="font-semibold text-[11px] mb-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]  ">
                      {item.jobtitle1}
                    </h3>
                    <p>{item.jobdescription}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {educationData[0] ? (
          <div className="w-full border-l-8 border-gray-500 px-3 my-4 max-[650px]:my-2  py-2  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4">
            <h2 className="mb-1">
              <u className="font-semibold text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                Education
              </u>
            </h2>
            {educationData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col my-2 text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] "
                >
                  <div className="flex justify-between w-full mb-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                    <h3 className="font-semibold   max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      {item.school}, {item.city1}{" "}
                    </h3>
                    <h3>
                      {item.start} - {item.end}
                    </h3>
                  </div>
                  <div>
                    <h3 className="font-semibold  mb-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      {item.degree}
                    </h3>
                    <p>{item.edudescription}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {customSectionData[0] ? (
          <div className="w-full border-l-8 border-gray-500 px-3 my-4 max-[650px]:my-2  py-2  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4">
            {customSectionData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col my-2 text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] "
                >
                  <h2 className="mb-1">
                    <u className="font-semibold text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      {item.sectionTitle}
                    </u>
                  </h2>
                  <div className="flex justify-between w-full mb-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                    <h3 className="font-semibold   max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                      {item.activity}, {item.customSectionCity}{" "}
                    </h3>
                    <h3>
                      {item.customStartDate} - {item.customEndDate}
                    </h3>
                  </div>
                  <div>
                    <p>{item.customDescription}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {personalInfoValues.clearance ? (
          <div className="flex gap-2 w-full border-l-8 border-gray-400 px-3 max-[650px]:my-2  my-4 py-2  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:border-l-4 items-center text-[11px]">
            <h2 className="font-semibold">Security Clearance :</h2>
            <p>{personalInfoValues.clearance}</p>
          </div>
        ) : null}
      </div>

      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        <PDFDownloadLink
          document={<Resume7Download />}
          style={{
            textDecoration: "none",
            padding: "5px 8px",
            color: "black",
            fontWeight: 500,
            backgroundColor: "white",
            border: "1px solid #4A90E2",
            borderRadius: "4px",
            margin: "10px 0",
          }}
        >
          Download PDF
        </PDFDownloadLink>
        {/* {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume7Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            Download PDF
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Resume7;
