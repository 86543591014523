import React from "react";
import { useContext } from "react";
import { FaFile, FaPage4, FaRegBell } from "react-icons/fa";
import { UserContext } from "../../Dashboard";
import { Link, useNavigate } from "react-router-dom";
import { PiWalletFill } from "react-icons/pi";
import { RiCustomerService2Fill, RiNotification2Fill } from "react-icons/ri";
import axios from "axios";
// Images
import profileImage from "../../../../assets/images/profileImage.png";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../../../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { setChooseTemplate } from "../../../../state/reducers/choosetemplateSlice";
import { AiOutlineFile } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { LiaIdCardSolid } from "react-icons/lia";
export default function DahboardTopBar(props) {
  const [user, setUser] = useState("user");
  const navigate = useNavigate();
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const getUser = async () => {
    const token = localStorage.getItem("authToken");
    await axios({
      method: "get",
      url: `/api/v1/getUser`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        console.log(response);
        setUser(response.data.userData.name);

        if (response.data.userData.plan === "basic") {
          console.log("basic");
          setIsButtonVisible(false);
        }
      })
      .catch((err) => {
        navigate("/auth/login");
      });
  };
  useEffect(() => {
    getUser();
  }, []);
  const dispatch = useDispatch();
  const handleShowTemplate = () => {
    console.log("hello");
    dispatch(setChooseTemplate(true));
  };
  const [tabs, setTabs] = useState();
  const handleTabs = (index) => {
    setTabs(index);
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    // localStorage.removeItem("authToken");
    navigate("/");

    // window.location.href = "http://localhost:3000/";
  };
  return (
    <div>
      <div className="hidden w-full xl:flex flex-col gap-[20px] ">
        <div className="w-full  text-white bg-clearanceDarkBlue py-[25px] px-[50px] flex gap-[30px] justify-between items-center">
          <div className="flex gap-[30px] items-center">
            <Link to="/dashboard">
              <h2
                onMouseEnter={() => {
                  handleTabs(0);
                }}
                className="text-[25px] text-white font-semibold">
                Instant Ai Resume Builder
              </h2>
            </Link>
            <ul className="flex gap-[20px]">
              <li
                onMouseEnter={() => {
                  handleTabs(1);
                }}
                className="text-[17px] cursor-pointer font-medium">
                Products
              </li>
              {/* <Link
                to="/dashboard/payment"
                onMouseEnter={() => {
                  handleTabs(0);
                }}
                className="text-[17px] cursor-pointer font-medium">
                Payments
              </Link> */}
              <li
                onMouseEnter={() => {
                  handleTabs(2);
                }}
                className="text-[17px] cursor-pointer font-medium">
                Resources
              </li>
            </ul>
          </div>
          <div
            onMouseEnter={() => {
              handleTabs(0);
            }}
            className="w-[40%] flex justify-end">
            <button
              onClick={handleLogout}
              className="px-[30px] py-[12px] rounded-md hover:bg-clearancePink bg-transparent border-2 border-clearancePink text-clearancePink font-semibold hover:text-clearanceDarkBlue">
              Logout
            </button>
          </div>
        </div>
        <div
          onMouseLeave={() => {
            handleTabs(0);
          }}
          className="w-full">
          {tabs === 1 ? (
            <div className=" absolute w-full z-[5] bg-white shadow-2xl shadow-black top-[100px] left-0 pb-[20px] px-[20px] text-black flex flex-col items-center gap-[20px]">
              <h2 className="text-[22px] text-center w-full font-semibold border-b border-black pb-2">
                Our Products
              </h2>
              <div className="w-[90%] justify-center flex flex-wrap gap-[25px]">
                <Link
                  to="/dashboard"
                  className="w-[48%] sm:w-[45%] shadow-2xl hover:shadow-none transition-all p-[25px] flex items-center gap-[15px] cursor-pointer"
                  onClick={handleShowTemplate}>
                  <div>
                    <FaFile size={25} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[17px]  font-medium">Create Resume</h3>
                    <p className="text-[15px]">
                      Experience a seamless and user-friendly resume creation
                      process that empowers you to effortlessly articulate your
                      professional journey.
                    </p>
                  </div>
                </Link>
                <Link
                  to="/dashboard"
                  className="w-[45%] shadow-2xl hover:shadow-none transition-all p-[25px] flex items-center gap-[15px] cursor-pointer">
                  <div>
                    <AiOutlineFile size={25} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[17px]  font-medium">My Resumes</h3>
                    <p className="text-[15px]">
                      Our platform is engineered for simplicity, allowing you to
                      focus on showcasing your skills and achievements without
                      the unnecessary complexities.
                    </p>
                  </div>
                </Link>

                <Link
                  to="/dashboard/resume-formatting"
                  className="w-[45%] shadow-2xl hover:shadow-none transition-all p-[25px] flex items-center gap-[15px] cursor-pointer">
                  <div>
                    <FiFileText size={25} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[17px]  font-medium">Templates</h3>
                    <p className="text-[15px]">
                      Choose from an extensive array of meticulously crafted
                      templates, each designed to cater to diverse professional
                      styles and industry preferences.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ) : null}
        </div>

        {/* resources tabs */}
        <div
          onMouseLeave={() => {
            handleTabs(0);
          }}>
          {tabs === 2 ? (
            <div className="bg-white absolute w-full z-[5] shadow-2xl shadow-black top-[100px] left-0 pb-[20px] px-[20px] text-black flex flex-col items-center gap-[20px]">
              <h2 className="text-[22px] text-center w-full font-semibold border-b border-black pb-2">
                Resources
              </h2>
              <div className="w-[90%] justify-center flex flex-wrap gap-[25px]">
                <Link
                  to="/dashboard/my-profile"
                  className="w-[48%] sm:w-[45%] shadow-2xl hover:shadow-none transition-all p-[25px] flex items-center gap-[15px] cursor-pointer">
                  <div>
                    <LiaIdCardSolid size={25} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[17px]  font-medium">My Profile</h3>
                    <p className="text-[15px]">
                      Take control of your narrative by utilizing our
                      self-review feature. Conduct a comprehensive evaluation of
                      your resume, ensuring accuracy, coherence, and alignment
                      with industry standards.
                    </p>
                  </div>
                </Link>
                <Link
                  to="/contact-support"
                  className="w-[45%] shadow-2xl hover:shadow-none transition-all p-[25px] flex items-center gap-[15px] cursor-pointer">
                  <div>
                    <RiCustomerService2Fill size={25} />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[17px]  font-medium">
                      Customer Support
                    </h3>
                    <p className="text-[15px]">
                      Integrates a robust customer support system, ensuring
                      users have access to timely assistance, resolving queries,
                      and providing guidance throughout their interaction with
                      the website, enhancing overall user satisfaction.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="pb-3 flex justify-between	 bg-accountableDarkGreen pl-10 lg:pl-[22%] py-4 pr-10">
        {isButtonVisible ? (
          <h1></h1>
        ) : (
          <button
            className="bg-transparent hover:bg-[#3a415c] text-[#3a415c] font-semibold hover:text-white py-2 px-4 border border-[#3a415c] hover:border-transparent rounded"
            onClick={() => {
              navigate("/dashboard/payment");
            }}>
            Upgrade
          </button>
        )}
        <div className="flex items-center gap-x-10">
          <p className="text-xs" onClick={() => {}}>
            Hi! <strong>{user}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
