import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "../../../components/ui/Dashboard";
import { MdDelete } from "react-icons/md";
import { motion } from "framer-motion";
import Img1 from "../../../assets/resume/resume1.png";
import Img2 from "../../../assets/images/resume2.png";
import Img3 from "../../../assets/images/resume3.png";
import Img4 from "../../../assets/images/resume4.png";
import Img5 from "../../../assets/images/RESUME 5.png";
import Img6 from "../../../assets/resume/resume2.png";
import Img7 from "../../../assets/resume/resume3.png";
import Img8 from "../../../assets/resume/resume4.png";
import Img9 from "../../../assets/resume/resume5.png";
import Img10 from "../../../assets/resume/resume6.png";
import { selectTemplate } from "../../../state/reducers/templateSlice";
import { useDispatch, useSelector } from "react-redux";
function ResumeFormattingPage() {
  const Router = useNavigate();
  const [templatesIds, setTemplateIds] = useState([]);
  const token = localStorage.getItem("authToken");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `/api/v1/templates`,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setTemplateIds(response.data.templates);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);
  const onSubmit = async (values) => {
    const token = localStorage.getItem("authToken");
    const fileUrl = "/static/media/resume1.53925282b11e926d0907.png";

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      console.log(blob instanceof Blob); // Should be true

      const formData = new FormData();
      formData.append("image", blob, "screenshot.png");

      const axiosResponse = await axios({
        method: "post",
        url: "/api/v1/template",
        headers: {
          Authorization: "Bearer " + token,
        },
        data: formData,
      });
      console.log("here");
      const template = axiosResponse.data.templateid;
      console.log(template);
      Router(`/dashboard/create-resume/${template}`);
    } catch (err) {}
  };
  const deleteTemp = async (templateid) => {
    try {
      await axios({
        method: "delete",
        url: `/api/v1/delete-template/${templateid}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      fetchTemplates();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectTemplate = (templateId) => {
    if (selectedTemplate === templateId) {
      // Deselect if the same template is clicked again
      setSelectedTemplate(null);
    } else {
      // Select the new template
      setSelectedTemplate(templateId);
    }
  };
  const animDiv = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.5,
        damping: 1,
      },
    },
  };

  const templateImages = [
    Img1,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img2,
    Img3,
    Img4,
    Img5,
  ];
  const citiesName = [
    "Lisbon",
    "Rome",
    "Madrid",
    "Vanice",
    "Athens",
    "Porto",
    "Berlin",
    "Hamburg",
    "Munich",
    "Milan",
  ];

  const [hoveredTemplate, setHoveredTemplate] = useState(null);

  // Hover handlers
  const handleMouseEnter = (index) => {
    setHoveredTemplate(index);
  };

  const handleMouseLeave = () => {
    setHoveredTemplate(null);
  };
  const selectedTemplates = useSelector(
    (state) => state.template.selectedTemplate
  );
  const dispatch = useDispatch();
  return (
    <>
      <ToastContainer />
      {isUserLoggedIn && (
        <Dashboard page="resume-formatting">
          <section className="mt-12 relative text-clearanceDarkBlue">
            <h2 className="text-xl font-bold">Resume Templates</h2>

            <div className="flex gap-x-5 w-full mt-7">
              <motion.div
                variants={animDiv}
                initial="hidden"
                whileInView="visible"
                className="bg-white shadow-xl rounded-xl w-full min-h-[500px] px-5 py-2">
                <div className="flex flex-wrap gap-x-7 gap-y-12 py-5 justify-center mt-5">
                  {templateImages.map((img, index) => (
                    <div
                      key={index}
                      className="relative w-[220px] h-[250px] border border-black bg-white "
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}>
                      <img
                        src={img}
                        alt={`Resume Template ${index + 1}`}
                        className="w-full h-[240px] object-fill "
                      />
                      <div className="w-full flex mt-4 justify-center">
                        <h2>{citiesName[index]}</h2>
                      </div>

                      {hoveredTemplate === index ? (
                        <div className="absolute top-0 w-full h-full flex justify-center bg-black opacity-[0.7] items-center">
                          {selectedTemplate === index ? (
                            <button
                              onClick={() => handleSelectTemplate(index)}
                              className="px-4 py-2 text-green-500 border-2 border-green-500">
                              Selected
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                handleSelectTemplate(index);
                                dispatch(selectTemplate(index + 1));
                              }}
                              className="px-4 py-2 text-white border-2 border-white">
                              Select
                            </button>
                          )}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="flex justify-end my-7">
                  <button
                    className="px-4 py-2 bg-clearanceDarkBlue text-white rounded-md"
                    onClick={onSubmit}>
                    Create Resume
                  </button>
                </div>
              </motion.div>
            </div>
          </section>
        </Dashboard>
      )}
    </>
  );
}

export default ResumeFormattingPage;
